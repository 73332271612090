// Valtio
import { proxy, subscribe } from "valtio";


// STATE
const state = proxy({
  devMod: false,
  services: [
    { id: 1, name: "Solar 3D", default: true }
  ],
  getDefaultService: () => {
    let defaultService = state.services.filter((service) => service.default);
    return defaultService[0].name;
  },
  getOtherServices: () => state.services.filter((value) => !value.default),
  dropdownOpen: false,
  mobileMenuOpen: false,
  mobileLinks: [{ name: "Fiók", to: "https://account.solarvp.hu" }],
});

export const setDevMod = (value) => {
  state.devMod = value;
};

export const setDefaultService = (newDefault) => {
  let newDefaultService = state.services.find((e) => e.id === newDefault.id);
  let currentDefaultService = state.services.filter(
    (service) => service.default
  )[0];

  subscribe(state.services, () =>
    console.log("Changes in state:", "\n", state.services)
  );
  currentDefaultService.default = false;
  newDefaultService.default = true;
};


export const setDropDownOpen = (value) => {
  state.dropdownOpen = !value;
};

export const setMobileMenuOpen = (open) => {
  if (open) {
    state.mobileMenuOpen = true;
  } else {
    state.mobileMenuOpen = false;
  }
};


export default state;