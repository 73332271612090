import MobileMenu from "../Header/MobileMenu";

const MobileLayout = () => {
  return (
    <div className="header__mobile-menu">
      <MobileMenu />
    </div>
  );
};

export default MobileLayout;
