// Preprocess tools object
export const preprocessToolsObject = (obj) => {
    const newObj = {};
    let firstKey = null;
  
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === true) {
        if (firstKey === null) {
          firstKey = key;
          newObj[firstKey] = true;
        } else {
          newObj[key] = false;
        }
      }
    }
  
    return newObj;
  };