// Components import
import Icons from "../Icons/Icons";
import ModelViewer from "../ModelViewer/ModelViewer";
import ModelViewerEmpty from "../ModelViewer/ModelViewerEmpty";
import { Button } from "../Molecules/Buttons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PDFRoofConverter } from "../../utils/pdfConverter";

// Store
import { useSnapshot } from "valtio";
import StoreRequests from "../../data/store/Requests";

// API
import getZipFile from "../../api/request/getZipFile";

// Toast import
import { useToastContext } from "../Toast/Toast";
import QRGenerator from "../../utils/useQRCode";

const PanelPrevRequest = ({ data }) => {
  return (
    <>
      {data.status === "Available" ? (
        <RequestView data={data} />
      ) : (
        <RequestStatus />
      )}
    </>
  );
};

const RequestView = ({ data }) => {
  const {
    request_id,
    created_at,
    user,
    building_type,
    updated_at,
    model_json,
  } = useSnapshot(StoreRequests.activeRequestData);
  const addToast = useToastContext();

  const { address, coordinates } = data;
  const { canvasIMG } = useSnapshot(StoreRequests);

  const roundedString = (str) => {
    if (str !== null) {
      const number = parseInt(Number(str));
      return number.toString();
    }
  };

  // Model metadata
  const modelMetaData = [
    {
      name: "Tetőfelület",
      value: model_json ? model_json.calculations.roof_area : "-",
      unit: " m²",
    },
    {
      name: "Átlag dőlésszög",
      value: model_json ? model_json.calculations.avg_angle : "-",
      unit: "°",
    },
    {
      name: "Taréjgerinc hossz",
      value: model_json ? model_json.calculations.horizontal_ridge_length : "-",
      unit: " m",
    },
    {
      name: "Élgerinc hossz",
      value: model_json ? model_json.calculations.edge_ridge_length : "-",
      unit: " m",
    },
    {
      name: "Oromszegély hossz",
      value: model_json ? model_json.calculations.gable_length : "-",
      unit: " m",
    },
    {
      name: "Eresz hossz",
      value: model_json ? model_json.calculations.drain_length : "-",
      unit: " m",
    },
    {
      name: "Vápa hossz",
      value: model_json ? model_json.calculations.vapa_length : "-",
      unit: " m",
    },
    {
      name: "Napelemek",
      value: model_json ? model_json.calculations.solars.pcs : "-",
      unit: " db",
    },
  ];

  const modelMetaDataPDF = [
    {
      name: "10° feletti tetőfelület",
      value: model_json ? model_json.calculations.roof_area : "-",
      unit: " m²",
    },
    {
      name: "Lapostető felület",
      value: model_json ? model_json.calculations.flatroof_area : "-",
      unit: " m²",
    },
    {
      name: "Átlag dőlésszög",
      value: model_json ? model_json.calculations.avg_angle : "-",
      unit: "°",
    },
    {
      name: "Taréjgerinc hossz",
      value: model_json ? model_json.calculations.horizontal_ridge_length : "-",
      unit: " m",
    },
    {
      name: "Élgerinc hossz",
      value: model_json ? model_json.calculations.edge_ridge_length : "-",
      unit: " m",
    },
    {
      name: "Oromszegély hossz",
      value: model_json ? model_json.calculations.gable_length : "-",
      unit: " m",
    },
    {
      name: "Eresz hossz",
      value: model_json ? model_json.calculations.drain_length : "-",
      unit: " m",
    },
    {
      name: "Vápa hossz",
      value: model_json ? model_json.calculations.vapa_length : "-",
      unit: " m",
    },
    {
      name: "Manzárdtöres hossz",
      value: model_json ? model_json.calculations.manzard_length : "-",
      unit: " m",
    },
    {
      name: "Hajlásszögtörés hossz",
      value: model_json ? model_json.calculations.angular_break_length : "-",
      unit: " m",
    },
    {
      name: "Falszegély hossz",
      value: model_json ? model_json.calculations.wall_edge_length : "-",
      unit: " m",
    },
    {
      name: "Egyéb szegély hossz",
      value: model_json ? model_json.calculations.other_edge_length : "-",
      unit: " m",
    },
  ];

  const modelSolarDataPDF = [
    {
      name: "Panelek száma",
      value: model_json ? model_json.calculations.solars.pcs : "-",
      unit: " DB",
    },
    {
      name: "Panel szélesség",
      value: model_json ? model_json.calculations.solars.widths[0] : "-",
      unit: " M",
    },
    {
      name: "Panel magasság",
      value: model_json ? model_json.calculations.solars.heights[0] : "-",
      unit: " M",
    },
    {
      name: "Összterület",
      value: model_json
        ? roundedString(model_json.calculations.solars.area)
        : "-",
      unit: " m²",
    },
  ];

  // Format dates
  const formatDate = (unFormatedDate) => {
    let formatedDate = unFormatedDate.slice(0, 16);
    formatedDate = formatedDate.replaceAll("T", " ");
    formatedDate = formatedDate.replaceAll("-", ".");
    return formatedDate;
  };

  // Download zipped project files
  const downloadZIP = () => {
    getZipFile(request_id, addToast);
  };

  return (
    <div className="panel__content-box__viewer">
      <div className="panel__content-box__viewer__reqinfo">
        <ul>
          <li>
            <span>Igénylés ideje</span>
            <span className="span--bold">{formatDate(created_at)}</span>
          </li>
          <li>
            <span>Igényelte</span>
            <span className="span--bold">
              {user.last_name} {user.first_name}
            </span>
          </li>
          <li>
            <span>Típusa</span>
            <span className="span--bold">{building_type}</span>
          </li>
        </ul>
      </div>

      {model_json ? <ModelViewer /> : <ModelViewerEmpty />}

      {model_json && (
        <>
          <div className="panel__content-box__viewer__meta">
            <ul>
              {modelMetaData.map((item, index) => {
                if (item.value === 0 || item.value === "0.00") return null;
                return (
                  <li key={index}>
                    <span>{item.name}</span>
                    <span className="span--bold">
                      {item.value}
                      {item.unit}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="panel__content-box__viewer__qrcode">
            <QRGenerator value={`https://map.solarvp.hu/?svp=${request_id}`} />
          </div>
          <div className="panel__content-box__viewer__actions">
            <Button
              text="Fájlok letöltése"
              icon={<Icons.Download />}
              iconPosition="before"
              action={downloadZIP}
            />
            {canvasIMG !== null && request_id !== "" && (
              <PDFDownloadLink
                className="pdf__download"
                document={
                  <PDFRoofConverter
                    id={request_id}
                    address={address}
                    coordinates={coordinates}
                    modelMetaData={modelMetaDataPDF}
                    modelSolarData={modelSolarDataPDF}
                    canvasIMG={canvasIMG}
                  />
                }
                fileName={`SVP-${request_id}.pdf`}
              >
                {() => <Button text={"Adatlap nyomtatása"} secondary />}
              </PDFDownloadLink>
            )}
          </div>
        </>
      )}

      <div className="panel__content-box__viewer__footer">
        <a
          className="panel__content-box__viewer__footer__report"
          href={`mailto:info@solarvp.hu?subject=Probléma%20jelzése:%20SVP-${request_id}`}
        >
          <Icons.Help />
          <span className="span--bold">Probléma jelzése</span>
        </a>
        <div className="panel__content-box__viewer__footer__updated">
          <span>Adatok frissítve:</span>
          <span className="span--bold">{formatDate(updated_at)}</span>
        </div>
      </div>
    </div>
  );
};

const RequestStatus = () => {
  const { status, comment, request_id } = useSnapshot(
    StoreRequests.activeRequestData
  );

  const icons = {
    Pending: <Icons.PinProcessing color="black" />,
    Denied: <Icons.PinDenied color="white" />,
  };
  const headlines = {
    Pending: "Feldolgozás folyamatban",
    Denied: "Adatigény elutasítva",
  };
  const leads = {
    Pending:
      "A kiválasztott épülethez tartozó tető adatok feldolgozását megkezdtük. Amint elkészültünk, emailben értesítést küldünk.",
    Denied: "Az épülethez tartozó adatok feldolgozása sikertelen volt.",
  };

  return (
    <div className="panel__content-box__status">
      <span className={`panel__content-box__status-icon ${status}`}>
        {icons[status]}
      </span>
      <div className="panel__content-box__status-content">
        <h2>{headlines[status]}</h2>
        <p>{leads[status]}</p>
      </div>
      {comment && status === "Denied" && (
        <div className="panel__content-box__status-content__comment">
          <div className="panel__content-box__status-content__comment-message">
            <p>{comment}</p>
          </div>
          <a
            className="panel__content-box__status-content__comment-contact"
            href={`mailto:info@solarvp.hu?subject=SVP-${request_id}`}
          >
            Kapcsolatfelvétel
          </a>
        </div>
      )}
    </div>
  );
};

export default PanelPrevRequest;
