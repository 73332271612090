// OL Tile
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";

// OL Vector
import VectorLayer from "ol/layer/Vector";
import VectorSource from 'ol/source/Vector';

import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import {createXYZ} from 'ol/tilegrid';
import MVT from 'ol/format/MVT';

// GeoJSON and Style
import GeoJSON from 'ol/format/GeoJSON';
import { Fill, Style } from 'ol/style';
import { transformExtent } from "ol/proj";

// Pin
import PinStyle from "./Pin";

// Proj4
import proj4 from "proj4";
import { register } from "ol/proj/proj4";

// EPSG:23700 used by the availabilityLayer JSON data
proj4.defs('EPSG:23700',
  '+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs');
register(proj4);


// OSM BACKGROUND LAYER
export const backgroundLayer = new TileLayer({
  source: new XYZ({
    url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  }),
  name: "backgroundLayer",
  className: 'bw-layer',
  opacity: 0.6
});


// GM SATALITE LAYER
export const satalaiteLayer = new TileLayer({
  source: new XYZ({
    url: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
  }),
  name: "satalaiteLayer",
});


// ORTO LAYERS
export const ortoLayer = new TileLayer({
  source: new XYZ({
    url: "https://tileserver{1-8}.envimap.hu/Full_Merge_230404/{z}/{x}/{y}",
    crossOrigin: "Anonymous",
    minZoom: 8,
    maxZoom: 18,
    tilePixelRatio: 2,
  }),
  name: "topoOrtoLayer",
  extent: transformExtent(
    [16.380969,46.050858,22.970843,48.436549],
    'EPSG:4326','EPSG:3857'
  ),
});

export const modelortoLayer = new TileLayer({
  source: new XYZ({
    url: "https://tileserver{1-8}.envimap.hu/Full_Merge_230404/{z}/{x}/{y}",
    crossOrigin: 'anonymous',
    minZoom: 18,
    maxZoom: 18,
    tilePixelRatio: 2,
  }),
  name: "modelortoLayer",
});


// SURVEY DATA LAYER
const surveyStyle = new Style({
  fill: new Fill({
    color: 'transparent',
  })
});

const surveyStyleFunction = () => {
  return surveyStyle;
}

export const surveyLayer = new VectorTileLayer({
  source: new VectorTileSource({
    tilePixelRatio: 1, // oversampling when > 1
    tileGrid: createXYZ({
      maxZoom: 20,
      crossOrigin: 'anonymous',
    }),
    format: new MVT(),
    url: 'https://envimap.hu/geoserver/gwc/service/tms/1.0.0/SolarVP:SVP_dates@EPSG:900913@pbf/{z}/{x}/{-y}.pbf',
  }),
  style: surveyStyleFunction,
  minZoom: 12,
});


// AVAILABILITY LAYER
const availabilityStyle = new Style({
  fill: new Fill({
    color: 'transparent',
  }),
});
export const availabilityLayer = new VectorLayer({
  source: new VectorSource({
    projection: 'EPSG:3857',
    url: 'service-availability.json',
    format: new GeoJSON({
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    }),
  }),
  style: availabilityStyle
});


// NEW PIN LAYER
export const newPinLayer = new VectorLayer({
  source: new VectorSource(),
  style: PinStyle("select"),
  name: "newPinLayer"
});