import Row from "./Row";
import Icons from "../Icons/Icons";

// State and Valtio imports
import { useSnapshot } from "valtio";
import StoreGlobal from "../../data/store/Global";


const Legal = () => {
  const { surveyDate } = useSnapshot(StoreGlobal);

  return (
    <div className="content__left-bottom__left-legal">
      <span>{new Date().getFullYear()} - © Solar Viewpoint Kft.</span>
      <hr />
      {surveyDate &&
        <Attribution
          date={surveyDate}
        />
      }
      <a
        href="https://solarvp.hu/ASZF-SolarVP_latest.pdf"
        target="_blank"
        rel="noreferrer"
      >
        Általános Szerződési Feltételek
      </a>
    </div>
  );
};

const Attribution = ({date}) => {
  return (
    <>
      <span className="bold">Felmérés ideje: {date}</span>
      <hr />
    </>
  )
};

const ContentBottom = ({ setViewFullExtent, setZoomOut, setZoomIn }) => {
  return (
    <div className="content__left-bottom">
      <Row>
        <div className="content__left-bottom__left">
          <Legal />
        </div>
        <div className="content__left-bottom__right">
          <button onClick={() => setViewFullExtent()}>
            <Icons.Extent />
          </button>
          <div>
            <button onClick={() => setZoomIn()}>
              <Icons.Add />
            </button>
            <hr />
            <button onClick={() => setZoomOut()}>
              <Icons.Substract />
            </button>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default ContentBottom;
