// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken,
  cookieRemoveToken
} from "../cookie";
// Store
import {
  setUser,
  setUserLoggedIn
} from "../../data/store/Global";
// Linked API
import getInit from "../init";
import { GaEventLogin } from "../ga";



// Check error
const CheckError = (response) => {
  if (response.status === 200) {
    return response.json();
  } else {
    throw Error(response.statusText);
  }
};

// getProfile
const getProfile = async () => {

  await fetch(urls.profile, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => {

      // GA send login event
      GaEventLogin();

      // Redirect user if useing temporary password
      if (actualData.is_temp_password) {
        cookieRemoveToken();
        window.location.assign('http://account.solarvp.hu');
      }

      // Set returned user data
      setUser(actualData);
      // Set user logged in
      setUserLoggedIn(true);
      // Init
      getInit(actualData.domain_id);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getProfile;
