// React imports
import { createContext, useRef, useEffect, useState } from "react";

// React router
import { useSearchParams } from "react-router-dom";

// OL - Map and View
import Map from "ol/Map";
import View from "ol/View";

// OL - Controls
import { defaults as controlDefaults } from "ol/control/defaults";

// OL - Interactions
import { defaults as interactionDefaults } from "ol/interaction/defaults";

// OL - Icon and marker
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import PinStyle from "./Pin";

// OL - Extend
import { createEmpty, extend, getWidth } from "ol/extent";

// OL - Projection
import { fromLonLat, transform, transformExtent } from "ol/proj";

// OL - Vector cliping imports
import { getVectorContext } from "ol/render";
import { Fill, Style } from "ol/style";

// OL - Custom component imports
import {
  backgroundLayer,
  satalaiteLayer,
  ortoLayer,
  surveyLayer,
  availabilityLayer,
  newPinLayer,
} from "./Layers";
import { clusters, clusterCircleStyle, clusterCircles } from "./Clusters";

// Lodash
import throttle from "lodash.throttle";

// Component imports
import ContentTop from "../Layout/ContentTop";
import Brand from "../Header/Brand";
import Actions from "../Header/Actions";
import ContentBottom from "../Layout/ContentBottom";

// Toast import
import { useToastContext } from "../Toast/Toast";

// State and Valtio imports
import { useSnapshot } from "valtio";
import StoreGlobal, { setSurveyDate } from "../../data/store/Global";
import StoreRequests, {
  setActivePrevPin,
  clearActivePrevPin,
  setNewRequestPin,
  clearNewRequestPin,
  setNewRequestPinAddress,
} from "../../data/store/Requests";

// API
import getGeocode from "../../api/geocodeing/getGeocode";

// Cursor
import CrosshairCursor from "../../assets/images/Cursor";
import { Maptools } from "../Layout/Maptools";
import { findLayerByName } from "../../utils/layerValidator";

// Map View defaults
export const baseViewProjection = "EPSG:3857",
  baseViewCenter = [parseFloat("19.503304"), parseFloat("47.162494")],
  baseViewWMCenter = fromLonLat(baseViewCenter, baseViewProjection),
  initZoom = 8,
  maxZoom = 20,
  pinZoom = 18,
  maxViewExtent = () => {
    const c = baseViewWMCenter;
    const p = 500 * 1000;
    return [c[0] - p, c[1] - p, c[0] + p, c[1] + p];
  };

// Create MapContext context
const MapContext = createContext();

// OPENLAYERS MAP COMPONENT
const OLMap = () => {
  // snapshots
  const { searchBounds, searchPin } = useSnapshot(StoreGlobal);
  const {
    newRequestPin,
    activePrevPin,
    panelActive,
    prevRequests,
    requestChangeTrack,
  } = useSnapshot(StoreRequests);
  // map and layers
  const mapRef = useRef();
  const [map, setMap] = useState(null);
  const [clustersSource, setClustersSource] = useState(null);
  const [newPinSource, setNewPinSource] = useState(null);
  // features and clusters
  const [features, setFeatures] = useState(null);
  const [clustersInit, setClustersInit] = useState(false);
  const clusterPinObject = useRef();
  // url param
  const [urlParam, setUrlParam] = useSearchParams();
  const [urlParamInit, setUrlParamInit] = useState(false);
  // toast
  const addToast = useToastContext();

  // --- MOUNT MAP ---
  useEffect(() => {
    let options = {
      view: new View({
        projection: baseViewProjection,
        center: baseViewWMCenter,
        zoom: initZoom,
        maxZoom: maxZoom,
        extent: maxViewExtent(),
      }),
      layers: [
        backgroundLayer,
        availabilityLayer,
        satalaiteLayer,
        ortoLayer,
        surveyLayer,
        clusters,
        clusterCircles,
        newPinLayer,
      ],
      controls: controlDefaults({
        zoom: false,
        rotate: false,
      }),
      interactions: interactionDefaults({
        pinchRotate: false,
      }),
    };
    let mapObject = new Map(options);
    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    setClustersSource(clusters.getSource());
    setNewPinSource(newPinLayer.getSource());

    return () => mapObject.setTarget(undefined);
  }, []);

  // --- FEATURES ---
  // Set user owned features from Store
  useEffect(() => {
    if (prevRequests.length && features == null) {
      const featuresCount = prevRequests.length;
      const featuresArray = new Array(featuresCount);

      for (let i = 0; i < featuresCount; ++i) {
        const coordinates = [prevRequests[i].lng, prevRequests[i].lat];
        featuresArray[i] = new Feature(new Point(fromLonLat(coordinates)));

        // Add parameters to pin
        featuresArray[i].request_id = prevRequests[i].request_id;
        featuresArray[i].status = prevRequests[i].status;
        featuresArray[i].address = prevRequests[i].address;
        featuresArray[i].selected = false;
      }
      setFeatures(featuresArray);
    }
  }, [prevRequests, features]);

  // Add features to cluster source
  useEffect(() => {
    if (clustersSource) {
      if (features && clustersSource.getSource().isEmpty()) {
        clustersSource.getSource().addFeatures(features);
        setClustersInit(true);
      }
    }
  }, [clustersSource, features]);

  // Handle freshly requested pin
  useEffect(() => {
    if (requestChangeTrack > 0) {
      // Find the freshly requested item
      const freshRequest = prevRequests.find(
        (item) => item.request_id === requestChangeTrack
      );

      if (freshRequest) {
        // Create a new feature
        const freshFeature = new Feature(
          new Point(fromLonLat([freshRequest.lng, freshRequest.lat]))
        );

        // Add parameters to pin
        freshFeature.request_id = freshRequest.request_id;
        freshFeature.status = freshRequest.status;
        freshFeature.address = freshRequest.address;
        freshFeature.selected = false;

        // Add fresh feature to the existing features
        setFeatures((prevState) => ({
          ...prevState,
          freshFeature,
        }));

        // Add feature to the clusterSource
        clustersSource.getSource().addFeature(freshFeature);
      }
    }
  }, [requestChangeTrack, clustersSource, prevRequests]);

  // --- URL SEARCH PARAM ---
  // Check Initial UrlParam
  useEffect(() => {
    if (clustersInit && !urlParamInit) {
      const initId = urlParam.get("svp");

      if (initId) {
        // Find ID inside prevRequests
        let findRequestID = prevRequests.find(
          (e) => e.request_id.toString() === initId
        );

        // Go ahead if ID matched
        if (findRequestID) {
          // Transform coordinates
          const coords = transform(
            [findRequestID.lng, findRequestID.lat],
            "EPSG:4326",
            "EPSG:3857"
          );
          // Set cluster pin
          setActivePrevPin({
            request_id: findRequestID.request_id,
            coordinates: coords,
          });
        } else {
          // Throw error toast to user if ID doesn't match
          addToast([
            "error",
            `A keresett azonosító (SVP-${initId}) nem elérhető!`,
          ]);
        }
      }
      // Set init state
      setUrlParamInit(true);
    }
  }, [clustersInit, urlParam, prevRequests, urlParamInit, addToast]);

  // Handle UrlParam Change when activePrevPin state changes
  useEffect(() => {
    if (urlParamInit) {
      if (activePrevPin) {
        // Set URLSearchParam -> ID
        setUrlParam({ svp: activePrevPin.request_id });
      } else {
        // Unset URLSearchParam -> ID
        urlParam.delete("svp");
        setUrlParam(urlParam);
      }
    }
  }, [urlParamInit, activePrevPin, urlParam, setUrlParam]);

  // --- PIN CHANGES ---
  // Handle ClusterPin state changes
  useEffect(() => {
    if (clustersSource) {
      if (activePrevPin) {
        // Set PREVIOUS clusterPinObject unselected and update style
        if (clusterPinObject.current) {
          clusterPinObject.current.selected = false;
          clusterPinObject.current.setStyle(PinStyle());
        }

        // Set clusterPinObject selected and update style
        clusterPinObject.current = clustersSource
          .getSource()
          .getFeaturesAtCoordinate(activePrevPin.coordinates)[0];

        // Move view to pin
        const getView = map.getView();
        getView.animate({
          duration: 400,
          center: activePrevPin.coordinates,
          zoom: getView.getZoom() <= pinZoom ? pinZoom : getView.getZoom(),
        });

        // Update pin style
        clusterPinObject.current.selected = true;
        clusterPinObject.current.setStyle(PinStyle());
      } else {
        // Set PREVIOUS clusterPinObject unselected and update style
        if (clusterPinObject.current) {
          clusterPinObject.current.selected = false;
          clusterPinObject.current.setStyle(PinStyle());
        }
      }
    }
  }, [activePrevPin, clustersSource, map]);

  // Handle NewPin state changes
  useEffect(() => {
    if (newPinSource) {
      if (newRequestPin) {
        // Create new selection pin
        const newPinFeature = new Feature({
          geometry: new Point(
            fromLonLat([newRequestPin.lng, newRequestPin.lat])
          ),
        });

        // Clear newPinSource then add selection pin
        newPinSource.clear();
        newPinSource.addFeature(newPinFeature);

        // Move view to pin
        const getView = map.getView();
        getView.animate({
          duration: 400,
          center: fromLonLat([newRequestPin.lng, newRequestPin.lat]),
          zoom: getView.getZoom() <= pinZoom ? pinZoom : getView.getZoom(),
        });

        // Reverse Geocode pin address
        if (newRequestPin.available) {
          getGeocode(newRequestPin.lat, newRequestPin.lng);
        } else {
          setNewRequestPinAddress("");
        }
      } else {
        // Clear newPinSource
        newPinSource.clear();
      }
    }
  }, [newRequestPin, newPinSource, map]);

  // Resize map if a panelActive state changes
  useEffect(() => {
    if (map) {
      setTimeout(function () {
        map.updateSize();
      }, 0); //setTimeout needed
    }
  }, [panelActive, map]);

  // --- INTERACTION ---
  // Handle Map HOVER and CLICK events
  useEffect(() => {
    if (map) {
      // Utility function to check attribution under pixel
      const getSurveyFeature = (pixel) =>
        map.forEachFeatureAtPixel(
          pixel,
          (feature) => {
            return feature;
          },
          {
            layerFilter: (layer) => {
              return layer === surveyLayer;
            },
          }
        );

      // Map view change events
      map.on("moveend", () => {
        if (map.getView().getZoom() < 12) {
          setSurveyDate(null);
        } else {
          const mapCenter = map.getPixelFromCoordinate(
            map.getView().getCenter()
          );
          const surveyFeature = getSurveyFeature(mapCenter);

          let surveyDate = surveyFeature
            ? surveyFeature.properties_.Rep_Ido
            : null;

          setSurveyDate(surveyDate);
        }
      });

      // Map Hover events
      map.on(
        "pointermove",
        throttle((e) => {
          // Utility function to check features under pixel
          const getFeature = (pixel) =>
            map.forEachFeatureAtPixel(
              pixel,
              (feature) => {
                return feature;
              },
              {
                layerFilter: (layer) => {
                  return layer !== newPinLayer && layer !== availabilityLayer;
                },
              }
            );

          // Change cursor if it is hovered on feature
          map.getViewport().style.cursor = getFeature(e.pixel)
            ? "pointer"
            : CrosshairCursor;
        }, 100)
      );

      // Map Click events
      map.on("click", (e) => {
        const newPinLayer = findLayerByName(map, "newPinLayer");
        if (newPinLayer !== null) {
          clusters.getFeatures(e.pixel).then((features) => {
            // Feature is available
            if (features.length > 0) {
              const clusterMembers = features[0].get("features");

              if (clusterMembers.length > 1) {
                // Calculate the extent of the cluster members.
                const extent = createEmpty();
                clusterMembers.forEach((feature) =>
                  extend(extent, feature.getGeometry().getExtent())
                );
                const view = map.getView();
                const resolution = map.getView().getResolution();
                if (
                  view.getZoom() === view.getMaxZoom() ||
                  (getWidth(extent) < resolution &&
                    getWidth(extent) < resolution)
                ) {
                  // Show an expanded view of the cluster members.
                  clusterCircles.setStyle(clusterCircleStyle);
                } else {
                  // Zoom to the extent of the cluster members.
                  view.fit(extent, {
                    duration: 500,
                    padding: [250, 250, 250, 250],
                  });
                }
              }

              // Cluster members are expanded
              if (clusterMembers.length === 1) {
                // Get rid of newRequestPin if there is any
                if (newRequestPin) {
                  clearNewRequestPin();
                }

                // Find and set activePrevPin
                let clickedClusterPin = clustersSource
                  .getSource()
                  .getClosestFeatureToCoordinate(e.coordinate);

                // Set the ClusterPin state to the selected pin
                setActivePrevPin({
                  request_id: clickedClusterPin.request_id,
                  coordinates: clickedClusterPin.getGeometry().getCoordinates(),
                });
              }
            }

            // Feature is not available
            else {
              // Get rid of activePrevPin if there is any
              if (activePrevPin) {
                clearActivePrevPin();
              }

              // Transform new pin coordinates
              const [convertedNewPinLng, convertedNewPinLat] = transform(
                e.coordinate,
                "EPSG:3857",
                "EPSG:4326"
              );

              // Check data availability - pixel alpha method
              // const ortoPixelData = ortoLayer.getData(e.pixel);
              // const newPinAvailability = ortoPixelData && ortoPixelData[3] !== 0;

              // Check data availability - pixel alpha method
              const checkAvailability = (pixel) =>
                map.forEachFeatureAtPixel(
                  pixel,
                  (feature) => {
                    return feature;
                  },
                  {
                    layerFilter: (layer) => {
                      return layer === availabilityLayer;
                    },
                  }
                );

              const newPinAvailability = checkAvailability(e.pixel);

              // Check survey data
              const newPinSurveyFeature = getSurveyFeature(e.pixel);

              let newPinSurveyDate = newPinSurveyFeature
                ? newPinSurveyFeature.properties_.Rep_Ido
                : null;

              if (newPinSurveyDate) {
                newPinSurveyDate =
                  newPinSurveyDate.slice(0, 4) +
                  "." +
                  newPinSurveyDate.slice(4, 6) +
                  "." +
                  newPinSurveyDate.slice(6) +
                  ".";
              }

              // Set state - newRequestPin and PanelType
              setNewRequestPin(
                convertedNewPinLng,
                convertedNewPinLat,
                newPinAvailability,
                newPinSurveyDate
              );
            }
          });
        }
      });
    }
  }, [
    map,
    panelActive,
    activePrevPin,
    newRequestPin,
    clustersSource,
    prevRequests,
  ]);

  // Zoom in and out
  const setZoom = (value) => {
    let getView = map.getView();
    getView.animate({
      zoom: getView.getZoom() + value,
      duration: 200,
    });
  };

  // Set view to full extent
  const setViewFullExtent = () => {
    let getView = map.getView();
    getView.animate(
      { center: getView.setCenter(baseViewWMCenter) },
      { zoom: initZoom }
    );
  };

  // Navigate to the searched area bounds
  useEffect(() => {
    if (searchBounds.length > 0) {
      const transSearchBounds = transformExtent(
        searchBounds,
        "EPSG:4326",
        "EPSG:3857"
      );
      let getView = map.getView();
      getView.fit(transSearchBounds, { duration: 600 });
    }
  }, [searchBounds, activePrevPin, map]);

  // Set pin
  useEffect(() => {
    if (map) {
      if (searchPin) {
        setTimeout(() => {
          const checkAvailability = (map, checkedPixel, checkedLayer) => {
            return map.forEachFeatureAtPixel(
              checkedPixel,
              (feature) => feature,
              {
                layerFilter: (layer) => layer === checkedLayer,
              }
            );
          };

          setNewRequestPin(
            searchPin[0],
            searchPin[1],
            checkAvailability(map, searchPin, availabilityLayer)
          );
        }, 500);
      }
    }
  }, [searchPin, map]);

  // Clip Google satalite layer with available area
  useEffect(() => {
    if (map) {
      // Set Extent
      availabilityLayer.getSource().on("addfeature", () => {
        satalaiteLayer.setExtent(availabilityLayer.getSource().getExtent());
      });
      // Style cliping
      const style = new Style({
        fill: new Fill({
          color: "black",
        }),
      });
      // Clip layer
      satalaiteLayer.on("postrender", (e) => {
        const vectorContext = getVectorContext(e);
        e.context.globalCompositeOperation = "destination-in";
        availabilityLayer.getSource().forEachFeature(function (feature) {
          vectorContext.drawFeature(feature, style);
        });
        e.context.globalCompositeOperation = "source-over";
      });
    }
  }, [map]);

  return (
    <>
      <ContentTop>
        <Brand />
        <Actions />
      </ContentTop>
      <MapContext.Provider value={{ map: map }}>
        <div className="map" ref={mapRef} />
      </MapContext.Provider>
      <Maptools map={map} />
      <ContentBottom
        setZoomIn={() => setZoom(+1)}
        setZoomOut={() => setZoom(-1)}
        setViewFullExtent={setViewFullExtent}
      />
    </>
  );
};

export default OLMap;
