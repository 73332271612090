import { PDFLogo } from "../assets/svg/icons";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  Canvas,
} from "@react-pdf/renderer";
import IBM_Plex_Mono_Bold from "../assets/fonts/ibm-plex-mono/IBMPlexMono-Bold.ttf";
import IBM_Plex_Mono_Regular from "../assets/fonts/ibm-plex-mono/IBMPlexMono-Regular.ttf";
import IBM_Plex_Sans_Bold from "../assets/fonts/ibm-plex-sans/IBMPlexSans-Bold.ttf";
import IBM_Plex_Sans_Regular from "../assets/fonts/ibm-plex-sans/IBMPlexSans-Regular.ttf";
import placeholderIMG from "../assets/images/placeholder.png";

// Create config
const PDFConfig = {
  document: {
    author: "Envirosense Hungary Kft.",
    children: "",
    creator: "EnviTető",
    keywords: "",
    subject: "",
    title: "",
  },
  page: {
    size: "A4",
  },
};

// Create styles
Font.register({
  family: "IBM Plex Mono",
  fonts: [
    { src: IBM_Plex_Mono_Regular, fontWeight: "normal" },
    { src: IBM_Plex_Mono_Bold, fontWeight: "bold" },
  ],
});
Font.register({
  family: "IBM Plex Sans",
  fonts: [
    { src: IBM_Plex_Sans_Regular, fontWeight: "normal" },
    { src: IBM_Plex_Sans_Bold, fontWeight: "bold" },
  ],
});

// Important: The number dimensions are in viewport width (vw) instead of px.
// Use converter to calculations: https://www.quick-tools.net/18px-to-vw-1000vp
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "4vw 6vw",
    fontFamily: "IBM Plex Sans",
  },
  section: {
    paddingLeft: "6vw",
    paddingRight: "6vw",
    width: "88vw",
  },
  sectionLogo: {
    marginBottom: "3vw",
  },
  sectionHeaderData: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sectionImages: {
    width: "88vw",
    marginTop: "5vw",
    gap: "1vw",
  },
  sectionDataTitle: {
    marginTop: "5vw",
    position: "relative",
  },
  sectionDataTitleLine: {
    position: "absolute",
    top: "1.25vw",
    left: "13vw",
    width: "59vw",
    height: ".1vw",
    backgroundColor: "#EEEEEE",
  },
  sectionData: {
    marginTop: "2.5vw",
    flexDirection: "column",
    gap: "3vw",
  },
  sectionDataRow: {
    flexDirection: "row",
    gap: "3vw",
  },
  logo: {
    width: "18.75vw",
    height: "5vw",
  },
  QRCode: {
    width: "9vw",
    height: "9vw",
  },
  textID: {
    fontFamily: "IBM Plex Mono",
    fontWeight: "bold",
    fontSize: "1.8vw",
    textTransform: "uppercase",
    color: "#0A0A29",
    paddingBottom: ".5vw",
  },
  textAddress: {
    fontSize: "2.4vw",
    color: "#454554",
    paddingBottom: "1vw",
  },
  textCoordinates: {
    fontSize: "1.4vw",
    fontWeight: "bold",
    color: "#454554",
  },
  ImagesContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "1vw",
  },
  ImageHalf: {
    flex: 1,
    height: "33vw",
    objectFit: "cover",
  },
  ImageQuarter: {
    flex: 1,
    height: "16.5vw",
    objectFit: "cover",
  },
  dataTitle: {
    display: "flex",
    color: "#454554",
    fontSize: "2vw",
    fontWeight: "bold",
  },
  dataCard: {
    width: "22vw",
  },
  dataCardTitle: {
    fontSize: "1.5vw",
    fontWeight: "bold",
    paddingBottom: "0.5vw",
    color: "#454554",
  },
  dataCardInf: {
    fontFamily: "IBM Plex Mono",
    fontSize: "2.5vw",
    fontWeight: "normal",
    textTransform: "uppercase",
  },
  footer: {
    position: "absolute",
    left: "12vw",
    right: "12vw",
    bottom: "3vw",
    paddingTop: "1.5vw",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid #EEEEEE",
  },
  footerCopyright: {
    fontSize: "1vw",
    color: "#454554",
  },
});

// Screenshot view planar directions
/* const cloudinaryURL = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_ENV}/image/upload`;
const planarViews = ["northView", "eastView", "southView", "westView"]; */

// Grid item for data
const GridItem = ({ metadata }) => (
  <View style={styles.dataCard}>
    <Text style={styles.dataCardTitle}>{metadata.name}</Text>
    <Text style={styles.dataCardInf}>{metadata.value + metadata.unit}</Text>
  </View>
);

// Create Document Component
export const PDFRoofConverter = ({
  id,
  address,
  coordinates,
  modelMetaData,
  modelSolarData,
  canvasIMG,
}) => {
  const filteredMetaData = modelMetaData.filter(
    (item) => item.value !== 0 && item.value !== "0.00" && item.value
  );

  const filteredSolarData = modelSolarData.filter(
    (item) => item.value !== 0 && item.value !== "0.00" && item.value
  );

  const generateGrid = (data) => {
    const rows = [];
    for (let i = 0; i < data.length; i += 3) {
      const row = (
        <View key={i} style={styles.sectionDataRow}>
          {data[i] && <GridItem metadata={data[i]} />}
          {data[i + 1] && <GridItem metadata={data[i + 1]} />}
          {data[i + 2] && <GridItem metadata={data[i + 2]} />}
        </View>
      );
      rows.push(row);
    }
    return rows;
  };

  const generateGridFour = (data) => {
    const rows = [];
    for (let i = 0; i < data.length; i += 4) {
      const row = (
        <View key={i} style={styles.sectionDataRow}>
          {data[i] && <GridItem metadata={data[i]} />}
          {data[i + 1] && <GridItem metadata={data[i + 1]} />}
          {data[i + 2] && <GridItem metadata={data[i + 2]} />}
          {data[i + 3] && <GridItem metadata={data[i + 3]} />}
        </View>
      );
      rows.push(row);
    }
    return rows;
  };

  // Coordinates slice util
  const sliceCoordinate = (coordinate) => coordinate.toString().slice(0, 11);
  // Get QR code
  const dataUrl = document.getElementById("QR_CODE_ID").toDataURL();

  return (
    <Document {...PDFConfig.document}>
      <Page {...PDFConfig.page} style={styles.page}>
        <View style={styles.section}>
          <View style={styles.sectionLogo}>
            <PDFLogo />
          </View>
          <View style={styles.sectionHeaderData}>
            <View>
              <Text style={styles.textID}>{`TET-${id}`}</Text>
              <Text style={styles.textAddress}>{address}</Text>
              <Text style={styles.textCoordinates}>
                {sliceCoordinate(coordinates.lng)},{" "}
                {sliceCoordinate(coordinates.lat)}
              </Text>
            </View>
            <View style={styles.QRCode}>
              <Image allowDangerousPaths src={dataUrl} style={styles.QRCode} />
            </View>
          </View>
        </View>

        {canvasIMG !== null && (
          <View style={styles.sectionImages}>
            <View style={styles.ImagesContainer}>
              <Image
                allowDangerousPaths
                style={styles.ImageHalf}
                src={canvasIMG}
              />
              <Image
                allowDangerousPaths
                style={styles.ImageHalf}
                src={placeholderIMG}
              />
            </View>
            <View style={styles.ImagesContainer}>
              {[0, 1, 2].map((view) => (
                <Image
                  key={view}
                  allowDangerousPaths
                  style={styles.ImageQuarter}
                  src={placeholderIMG}
                />
              ))}
            </View>
          </View>
        )}

        <View style={styles.section}>
          <View style={styles.sectionDataTitle}>
            <Text style={styles.dataTitle}>Tető adatok</Text>
            <Canvas style={styles.sectionDataTitleLine} />
          </View>
          <View style={styles.sectionData}>
            {generateGrid(filteredMetaData)}
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.sectionDataTitle}>
            <Text style={styles.dataTitle}>Napelemek</Text>
            <Canvas style={styles.sectionDataTitleLine} />
          </View>
          <View style={styles.sectionData}>
            {generateGridFour(filteredSolarData)}
          </View>
        </View>

        <View style={styles.footer}>
          <View>
            <Text style={styles.footerCopyright}>
              Készült a Solar ViewPoint Kft. szolgáltatásával.
            </Text>
            <Text style={styles.footerCopyright}>
              Az adatok tájékoztató jellegűek. Az esetleges pontatlanságokból
              eredő hibákért nem áll módunkban felelősséget vállalni.
            </Text>
          </View>
          <View>
            <Text style={styles.footerCopyright}>
              {new Date().toJSON().slice(0, 10).replace(/-/g, ".")}.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
