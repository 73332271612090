//import { useState } from "react";
import Icons from "../Icons/Icons";
import Row from "../Layout/Row";
//import { useSnapshot } from "valtio";
//import state, { setDefaultService } from "../../state";

/* const ServiceDropDown = () => {
  const snap = useSnapshot(state);
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <div className="header__brand-dropdown">
      <div
        className={`header__brand-dropdown__header ${open ? "open" : ""}`}
        role="button"
        onClick={() => toggle(!open)}
      >
        <p>{snap.getDefaultService()}</p>
        <Icons.ArrowDown />
      </div>
      {open && (
        <ul className="header__brand-dropdown__list">
          {snap.getOtherServices().map((item) => {
            return (
              <li className="header__brand-dropdown__list-item" key={item.id}>
                <button
                  type="button"
                  onClick={() => {
                    setDefaultService(item);
                    toggle(!open);
                  }}
                >
                  {item.name}
                  <Icons.ArrowRight />
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}; */

const Brand = () => {
  return (
    <div className="header__brand">
      <Row>
        <div className="header__brand-logo">
          <a href="https://account.solarvp.hu/">
            <Icons.Logo />
          </a>
        </div>
      </Row>
    </div>
  );
};

export default Brand;
