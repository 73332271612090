import {useEffect} from "react";

// State
import { useSnapshot } from "valtio";
import StoreGlobal from "./data/store/Global";
import StoreRequests from "./data/store/Requests";

// Components
import ContentLeft from "./components/Layout/ContentLeft";
import ContentRight from "./components/Layout/ContentRight";
import Row from "./components/Layout/Row";
import Panel from "./components/Panel/Panel";
import MobileLayout from "./components/Layout/MobileLayout";
import { ToastContextProvider } from "./components/Toast/Toast";

// Cookie import
import {
  cookieGetTempPassword,
  cookieGetToken
} from "./api/cookie";

// API call
import getProfile from "./api/profile/getProfile";
import { GaInit } from "./api/ga";

// View Height calculation utility
const calculateVh = () => {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
};


// APP
const App = () => {
  const { panelActive } = useSnapshot(StoreRequests);
  const { userLoggedIn } = useSnapshot(StoreGlobal);

  // Check logged in state with userToken cookie
  useEffect(() => {

    // Initialize Google Analytics
    GaInit();

    // Handle login cookie
    if (!cookieGetToken() || cookieGetTempPassword()) {
      window.location.assign('http://account.solarvp.hu');
    } else {
      getProfile();
    }
  }, []);

  // Fix mobile vh issue
  useEffect(() => {
    calculateVh();
    window.addEventListener('resize', calculateVh);
    window.addEventListener('orientationchange', calculateVh);
  }, []);

  // Fix mobile overflow problem
  useEffect(() => {
    if (!panelActive) {
      document.body.classList.add('fixed');
    } else {
      document.body.classList.remove('fixed');
    }
  }, [panelActive]);
  

  return userLoggedIn && (
    <ToastContextProvider>
      <div className="App">
        <Row class={`content ${panelActive ? "sidebar-active" : ""}`}>
          <MobileLayout />
          <ContentLeft/>
          <ContentRight>
            <Panel />
          </ContentRight>
        </Row>
      </div>
    </ToastContextProvider>
  );
};

export default App;
