// Base URL
const baseURL = "https://api.solarvp.hu/api/v1/";

// Routes
const users = `${baseURL}users/`;
const domains = `${baseURL}domains/`;
const request = `${users}request/`;

// Used urls in API calls
export const urls = {
  // Health
  health: `https://api.solarvp.hu/api/v1/public/health`,
  // User
  profile: `${users}profile`,
  // Domain
  domain: `${domains}`, // Needs {domain_id}
  // Request
  postNewRequest: `${request}`, // POST - New request
  getRequestsByDomain: `${request}domain/`, // GET - All prev requests by {domain_id}
  getRequestByID: `${request}`, // GET - Specific prev request by {request_id}
  
  getFile: `${request}file/`,
};