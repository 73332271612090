// State and Valtio imports
import StoreModel, {
  handleViewHomeing,
  handleViewFlyover
} from "../StoreModel";
import { useSnapshot } from "valtio";

// Component imports
import Icons from "../../Icons/Icons";


// VIEW ACTIONS
const ViewActions = () => {
  return (
    <>
      <ul className="viewactions">
        <Homeing/>
        <Flyover/>
      </ul>
    </>
  )
};

const Homeing = () => {
  return(
    <li>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleViewHomeing()
        }}
        title="Kamera alaphelyzetbe állítása"
      >
        <Icons.Home/>
      </button>
    </li>
  )
};

const Flyover = () => {
  const { autoRotate } = useSnapshot(StoreModel.view);

  return(
    <li className={autoRotate ? "active" : ""}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleViewFlyover()
        }}
        title="Autómatikus forgatás"
      >
        <Icons.AutoRotate/>
      </button>
    </li>
  )
};

export default ViewActions;