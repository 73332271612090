// State and Valtio imports
import StoreModel, {
  setModelViewerFullscreen
} from "../StoreModel";
import { useSnapshot } from "valtio";

// Component imports
import Icons from "../../Icons/Icons";


// FULLSCREEN
const Fullscreen = () => {
  const { fullScreen } = useSnapshot(StoreModel.view);

  return (
    <div
      className={`fullscreen ${fullScreen ? "active" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        setModelViewerFullscreen()
      }}
    >
      <div className="fullscreen__icon">
        <Icons.Expand
          expanded={fullScreen}
          color="white"
        />
      </div>
      <div className='fullscreen__overlay'/>
    </div>
  )
};

export default Fullscreen;