import React, { useRef, useState } from "react";

// Maps API
import { useLoadScript } from "@react-google-maps/api";

// use-places-autocomplete
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";

// State
import { useSnapshot } from "valtio";
import StoreGlobal, {
  setSearchInput,
  setSearchBounds,
} from "../../data/store/Global";

// Components
import Icons from "../Icons/Icons";

// Search settings
const SearchSettings = {
  libraries: ["places"],
  requestOptions: {
    types: ["address"],
    componentRestrictions: { country: "hu" },
  },
};

// SEARCHBAR - GM API wrapper
const SearchBar = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries: SearchSettings.libraries,
  });
  return !isLoaded || loadError ? null : <SearchBarComponent />;
};

// SEARCHBAR COMPONENT
const SearchBarComponent = () => {
  const { searchInputOpen } = useSnapshot(StoreGlobal);

  const inputRef = useRef();
  const [searchText, setSearchText] = useState(null);

  // Send search request
  const FormSubmitHandler = async (e) => {
    e.preventDefault();

    // If search input has value and isn't open
    if (searchText !== "" && searchText !== null && searchInputOpen) {
      // Once the search request has been sent, set
      // the value to selected, display the location
      // and close the suggestions.
      setValue(searchText, false);
      setSearchText(searchText);
      clearSuggestions();
      // Try...catch statement: the entered location
      // is sent to the geocode, from where we get back
      // lat and lng data, which the code uses to
      // coordinate us to the correct location.
      try {
        const results = await getGeocode({
          address: searchText,
        });

        const { geometry } = results[0];
        const { viewport } = geometry;

        const searchAddress = results[0].address_components;
        const searchIncludeStreetNum = searchAddress.filter(
          (addressComponent) => addressComponent.types.includes("street_number")
        );
        const needPinToSearch = searchIncludeStreetNum.length >= 1;

        const NELat = viewport.getNorthEast().lat()
        const NELng = viewport.getNorthEast().lng()
        const SWLat = viewport.getSouthWest().lat()
        const SWLng = viewport.getSouthWest().lng() 

        setSearchBounds(SWLng, SWLat, NELng, NELat, needPinToSearch);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Open search bar
  const SearchBarHandler = () => {
    // Open search bar if it's closed
    if (!searchInputOpen) {
      setSearchInput(true);
      inputRef.current.focus();
    }
  };

  // Clear and close search bar
  const SearchBarDeleteHandler = () => {
    // If the search input contains no character(s)
    if (searchText === "" || searchText == null) {
      setSearchInput(false);
    }
    // If search input contains character(s)
    if (searchText !== "") {
      setValue("");
      setSearchText("");
    }
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: SearchSettings.requestOptions,
    debounce: 300,
  });
  return (
    <form onSubmit={FormSubmitHandler}>
      <label>
        <>
          <button type="submit" onClick={() => SearchBarHandler()}>
            <Icons.Search />
          </button>
        </>
        <input
          ref={inputRef}
          type="text"
          placeholder="Keresés..."
          onChange={(e) => {
            setSearchText(e.target.value);
            setValue(e.target.value);
          }}
          value={value}
          disabled={!ready}
        />

        <ul className="header__actions-search__list">
          {status === "OK" &&
            data.map((suggestion) => {
              return (
                <button
                  type="submit"
                  onClick={() => {setSearchText(suggestion.description)}}
                  key={suggestion.place_id}
                >
                  {suggestion.description}
                  <Icons.ArrowRight />
                </button>
              );
            })}
        </ul>

        <button onClick={() => SearchBarDeleteHandler()}>
          <Icons.Close />
        </button>
      </label>
    </form>
  );
};

export default SearchBar;
