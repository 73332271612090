import * as THREE from "three";
import { Edges } from "@react-three/drei";


const Side = ({ data, view }) => {
  return (
    <>
      <mesh
        geometry={data}
        castShadow
        receiveShadow
      >
        <SideTexture view={view} layer={data.userData.layer} />
      </mesh>
    </>
  )
};

const SideTexture = ({ view, layer }) => {
  
  const opacity = {
    Alap:           [ 1, 0.1, 0.8, 1 ],
    Fal:            [ 1, 0.2, 0.6, 1 ],
    Teto:           [ 1, 0.4, 0.8, 1 ],
    Tetoablak:      [ 1, 0.2, 0.8, 1 ],
    Tetoelem:       [ 1, 0.2, 0.8, 1 ],
    Kemeny:         [ 1, 0.2, 0.8, 1 ],
    Gepeszetielem:  [ 1, 0.2, 0.8, 1 ],
  };
  const fillColor = {
    Alap:           [ "#0A0A29", "#FFFFFF", "#FFFFFF", "#0A0A29" ],
    Fal:            [ "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF" ],
    Teto:           [ "#FF865A", "#FFFFFF", "#A1A1AA", "#A1A1AA" ],
    Tetoablak:      [ "#7AD3D6", "#A1A1AA", "#EEEEEE", "#EEEEEE" ],
    Tetoelem:       [ "#7AD3D6", "#A1A1AA", "#EEEEEE", "#EEEEEE" ],
    Kemeny:         [ "#672D18", "#0A0A29", "#0A0A29", "#0A0A29" ],
    Gepeszetielem:  [ "#C3E070", "#A1A1AA", "#C3E070", "#EEEEEE" ],
  };
  const edgeColor = {
    Alap:           [ "#0A0A29", "#000000", "#454554", "#0A0A29" ],
    Fal:            [ "#FFFFFF", "#000000", "#A1A1AA", "#0A0A29" ],
    Teto:           [ "#454554", "#000000", "#454554", "#0A0A29" ],
    Tetoablak:      [ "#454554", "#000000", "#454554", "#0A0A29" ],
    Tetoelem:       [ "#454554", "#000000", "#454554", "#0A0A29" ],
    Kemeny:         [ "#454554", "#000000", "#454554", "#0A0A29" ],
    Gepeszetielem:  [ "#454554", "#000000", "#454554", "#0A0A29" ],
  };
  const errorColor = "#FF0055";

  switch(view) {
    case 0:
      return (
        <>
          <meshStandardMaterial
            side={THREE.DoubleSide}
            transparent
            opacity={layer in opacity ? opacity[layer][0] : 0.5}
            color={layer in fillColor ? fillColor[layer][0] : errorColor}
            metalness={0.5}
            roughness={0.8}
          />
          <Edges
            color={layer in edgeColor ? edgeColor[layer][0] : errorColor}
          />
        </>
      )
    case 1:
      return (
        <>
          <meshBasicMaterial
            side={THREE.DoubleSide}
            transparent
            opacity={layer in opacity ? opacity[layer][1] : 0.5}
            color={layer in fillColor ? fillColor[layer][1] : errorColor}
          />
          <Edges
            color={layer in edgeColor ? edgeColor[layer][1] : errorColor}
          />
        </>
      )
    case 2:
      return (
        <>
          <meshBasicMaterial
            side={THREE.DoubleSide}
            transparent
            opacity={layer in opacity ? opacity[layer][2] : 0.5}
            color={layer in fillColor ? fillColor[layer][2] : errorColor}
          />
          <Edges
            color={layer in edgeColor ? edgeColor[layer][2] : errorColor}
          />
        </>
      )
    case 3:
      return (
        <>
          <meshBasicMaterial
            side={THREE.DoubleSide}
            transparent
            opacity={layer in opacity ? opacity[layer][3] : 0.5}
            color={layer in fillColor ? fillColor[layer][3] : errorColor}
          />
          <Edges
            color={layer in edgeColor ? edgeColor[layer][3] : errorColor}
          />
        </>
      )
    default:
      return null
  }
};

export default Side;