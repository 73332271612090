import { useSnapshot } from "valtio";
import StoreDomain from "../../data/store/Domain";
import Icons from "../Icons/Icons";
import { Button } from "../Molecules/Buttons";

export const DataAvailability = ({ status }) => {
  const icons = [<Icons.Done color="white" />, <Icons.Close color="white" />];
  const headlines = ["Elérhető adatok", "Nincsenek elérhető adatok"];
  const leads = [
    "A kiválasztott épülethez tartozó adatok igénylése esetén három munkanapon belül elérheti azokat.",
    "A kiválasztott ponthoz sajnos jelenleg nem rendelkezünk adatokkal.",
  ];

  return (
    <div className="panel__content-box__status">
      <span
        className={`panel__content-box__status-icon ${
          status ? "Accepted" : "Denied"
        }`}
      >
        {status ? icons[0] : icons[1]}
      </span>
      <div className="panel__content-box__status-content">
        <h2>{status ? headlines[0] : headlines[1]}</h2>
        <p>{status ? leads[0] : leads[1]}</p>
      </div>
    </div>
  );
};

export const TrialEnded = () => {
  const { domain_name } = useSnapshot(StoreDomain);

  // Download zipped project files
  const sendUpgradeRequest = () => {
    const address = "info@solarvp.hu";
    const subject = "Csomagváltási igény - " + domain_name;
    const body = "";
    window.open(`mailto:${address}?subject=${subject}&body=${body}`);
  };

  return (
    <div className="panel__content-box__status">
      <span className="panel__content-box__status-icon trial-ended">
        <Icons.Pin />
      </span>
      <div className="panel__content-box__status-content">
        <h2>Ingyenes adatigényei elfogytak</h2>
        <p>
          A Demo időszak alatt engedélyezett három darab térítésmentes
          adatigénylését felhasználta.
        </p>
      </div>
      <Button text="Tovább használnám" action={sendUpgradeRequest} />
    </div>
  );
};
