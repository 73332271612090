// React imports
import { useRef, useMemo } from "react";

// Three imports
import { useLoader } from "@react-three/fiber";
import { Points, PointMaterial, PerformanceMonitor } from "@react-three/drei";
import { PCDLoader } from "three/examples/jsm/loaders/PCDLoader.js";

// State and Valtio imports
import { useSnapshot } from "valtio";
import StoreModel from "../StoreModel";
import StoreRequests from "../../../data/store/Requests";


// LIDAR
const Lidar = () => {
  const { pcd_file } = useSnapshot(StoreRequests.activeRequestData);
  const { currentView, lidarDetail, lidarDetailAuto } = useSnapshot(StoreModel.view);

  const pointsRef = useRef();
  const lidarData = useLoader(PCDLoader, pcd_file);

  // Handle performance calculation
  const handleAutoPerformance = (e) => {
    if (lidarDetail && lidarDetailAuto) {
      // Calculate how much change needed based on average FPS
      const change = Number(parseFloat(60/e.fps).toFixed());
      // Change details
      if (change > 1.5) {
        StoreModel.view.lidarDetail =  Number(parseFloat(lidarDetail + (change/2)).toFixed());
      }
    }
  };

  // Handle LiDAR point reduction
  const lidarPoints = useMemo(() => {
    if (lidarData && lidarDetail && currentView === 3) {
      // Reduced LiDAR array
      let reducedLidar = [];

      // Push coordinates selectively based on lidarDetail StoreModel
      for (var i = 0; i < lidarData.geometry.attributes.position.array.length; i = i+(lidarDetail*3)) {
        reducedLidar.push(
          lidarData.geometry.attributes.position.array[i],
          lidarData.geometry.attributes.position.array[i+1],
          lidarData.geometry.attributes.position.array[i+2]
        );
      };

      // Set the reduced point cloud
      return new Float32Array(reducedLidar);
    } else {
      return null
    };
  }, [lidarData, lidarDetail, currentView]);

  return (
    <>
      <PerformanceMonitor
        onDecline={(e) => handleAutoPerformance(e)}
      />
      <Points
        positions={lidarPoints}
        ref={pointsRef}
        rotation={[Math.PI / -2, 0, 0]}
        visible={currentView === 3}
      >
        <PointMaterial
          color={"#0A0A29"}
          transparent
          opacity={0.6}
          size={0.5 + (0.05 * lidarDetail)}
          depthWrite={true}
          sizeAttenuation={true}
        />
      </Points>
    </>
  )
};

export default Lidar;