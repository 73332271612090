// React
import { useState, useEffect, useMemo } from "react";
// Component imports
import { Button } from "../Molecules/Buttons";
import SegmentedControl from "../Molecules/SegmentedControl";
// Toast import
import { useToastContext } from "../Toast/Toast";

// Valtio and state imports
import { useSnapshot } from "valtio";
import StoreRequests from "../../data/store/Requests";
import StoreDomain from "../../data/store/Domain";

// API
import postNewRequest from "../../api/request/postNewRequest";
import { GaEventRequestInitiated } from "../../api/ga";
import { RadioButton } from "../Molecules/RadioButton";
import { DataAvailability, TrialEnded } from "./PanelElements";

const PanelNewRequest = ({ data }) => {
  const { prevRequests } = useSnapshot(StoreRequests);
  const { on_trial } = useSnapshot(StoreDomain);
  const addToast = useToastContext();

  const buildingFeatures = useMemo(
    () => [
      { name: "Épületmodell", priceResidential: 9000, priceIndustrial: 80 },
      {
        name: "Épületmodell tetőobjektumokkal",
        priceResidential: 11000,
        priceIndustrial: 90,
      },
      {
        name: "Épületmodell tetőobjektumokkal és napelem kiosztással",
        priceResidential: 12000,
        priceIndustrial: 100,
      },
    ],
    []
  );

  const solarDimensions = useMemo(
    () => [
      [1722, 1134],
      [1776, 1052],
      [1762, 1134],
      [2117, 1052],
      [2187, 1102],
      [2094, 1134],
      [2285, 1134],
      [2384, 1096],
      ["Egyéni méreteket adok meg"],
    ],
    []
  );

  // Form Data
  const [formData, setFormData] = useState({
    buildingType: "Lakóingatlan",
    buildingFeature: 0,
  });

  const [formDataSolarPanelDimension, setFormDataSolarPanelDimension] =
    useState(solarDimensions.length + 1);

  const [formDataSolarCustomDimension, setFormDataSolarCustomDimension] =
    useState({ dimensionWidth: 0, dimensionHeight: 0 });

  // Handle input change
  const handleInput = (e) => {
    setFormData((prevData) => {
      const { name, value, type, checked } = e.target;
      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleInputDimension = (data) => setFormDataSolarPanelDimension(data);

  const handleCustomInputDimension = (e) => {
    setFormDataSolarCustomDimension((prevData) => {
      const { name, value } = e.target;
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    // Prevent form to default function
    e.preventDefault();

    let solarSizes;

    if (formData.buildingFeature === "2") {
      if (formDataSolarPanelDimension === solarDimensions.length - 1) {
        solarSizes = [
          parseInt(formDataSolarCustomDimension.dimensionHeight),
          parseInt(formDataSolarCustomDimension.dimensionWidth),
        ];
      } else {
        solarSizes = solarDimensions[formDataSolarPanelDimension];
      }
    } else {
      solarSizes = [];
    }

    // Creating request body
    let body = {
      lat: data.coordinates.lat,
      lon: data.coordinates.lng,
      building_type: formData.buildingType,
      building_features: formData.buildingFeature,
      resolved_addr: data.address,
      service_id: "Solar",
      panel_size: JSON.stringify(solarSizes),
    };

    // Call endpoint
    postNewRequest(body, addToast);
  };

  // Analytics
  useEffect(() => {
    // Send new request initiated event to GA
    GaEventRequestInitiated(buildingFeatures[formData.buildingFeature].name);
  }, [formData.buildingFeature, buildingFeatures]);

  if (on_trial && prevRequests.length >= 3 && data.available) {
    return <TrialEnded />;
  } else {
    return (
      <>
        <DataAvailability status={data.available} />

        {data.available ? (
          <div className="panel__content-box__request">
            <form onSubmit={handleSubmit}>
              <div className="panel__content-box__request__switch">
                <div className="panel__content-box__request__head">
                  <p>Kérjük válasszon épület típust!</p>
                </div>
                <SegmentedControl
                  segments={[
                    {
                      label: "Lakóingatlan (kivéve társasház)",
                      value: "Lakóingatlan",
                    },
                    {
                      label: "Ipari, Közintézményi, Társasházi épület",
                      value: "Ipari létesítmény",
                    },
                  ]}
                  name={"buildingType"}
                  onChange={handleInput}
                  value={formData.buildingType}
                />
              </div>

              <div className="panel__content-box__request__checkbox">
                <div className="panel__content-box__request__head">
                  <p>Milyen adatokra van szüksége?</p>
                </div>
                <div className="panel__content-box__request__checkbox-buttons">
                  {buildingFeatures.map((item, index) => {
                    return (
                      <RadioButton
                        key={index}
                        index={index}
                        item={item}
                        name={"buildingFeature"}
                        onChange={handleInput}
                        value={formData.buildingFeature}
                        buildingType={formData.buildingType}
                        trial={on_trial}
                      />
                    );
                  })}
                </div>
                {formData.buildingFeature === "2" && (
                  <>
                    <div className="panel__content-box__request__head solar">
                      <p>Mekkora napelem mérettel számoljunk?</p>
                    </div>
                    <div className="panel__content-box__request__checkbox-buttons solar">
                      {solarDimensions.map((dimension, index) => {
                        return (
                          <RadioButton
                            key={index}
                            index={index}
                            item={
                              dimension.length === 1
                                ? dimension
                                : `${dimension[0]} mm x ${dimension[1]} mm`
                            }
                            name={"buildingFeature"}
                            onChange={() => handleInputDimension(index)}
                            value={formDataSolarPanelDimension}
                            inputs={index === solarDimensions.length - 1}
                            formDataSolarCustomDimension={formDataSolarCustomDimension}
                            handleCustomInputDimension={handleCustomInputDimension}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </div>

              <div className="panel__content-box__request__button">
                <Button text="Adatok igénylése" type="submit" value="submit" />
              </div>
              <span className="panel__content-box__request__miniscule">
                Az itt feltűntetett árak nettó árak, az Áfá-t nem tartalmazzák.
              </span>
            </form>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
};

export default PanelNewRequest;
