import {
  modify,
  modifyStyle,
  source,
  styleFunction,
  tipPoint,
} from "./styles.toolbar";
import { resetPrevDataFromMap } from "./utils.toolbar";
import { Draw } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
let draw, removeHighlight, remove;

const drawVectorLayer = new VectorLayer({
  source: source,
  style: (feature) => {
    return styleFunction(feature, true);
  },
});
drawVectorLayer.set('name', 'drawLayer');

// Draw interaction
const createDraw = ( map, interactionType, activeService ) => {

  // Add drawVectorLayer if not present
  let drawLayerExist = false;
  map.getLayers().forEach(function(layer) {
    if (layer.get('name') === 'drawLayer') {
      drawLayerExist = true;
    }
  });
  if (!drawLayerExist) {
    map.addLayer(drawVectorLayer);
  }

  // Remove unneeded interactions
  map.removeInteraction(removeHighlight);
  map.removeInteraction(remove);

  const addInteraction = () => {
    const drawType = interactionType;
    const idleTip = "Kattintson a kijelölés megkezdéséhez";
    let tip = idleTip;

    draw = new Draw({
      source: source,
      type: drawType,
      style: (feature) => {
        return styleFunction(feature, true, drawType, tip);
      },
    });

    draw.on("drawstart", () => {
      modify.setActive(false);
      tip = null;

      // If actice service is GeoStore clear cookie polygon
      if (activeService === 0) resetPrevDataFromMap(map);
    });

    // Draw end
    draw.on("drawend", () => {
      modifyStyle.setGeometry(tipPoint);
      modify.setActive(true);
      map.once("pointermove", () => {
        modifyStyle.setGeometry();
      });

    });

    // Add the interactions
    map.addInteraction(draw);
    map.addInteraction(modify);
  };

  addInteraction();
};

// Clear interaction
const clearDraw = ( map ) => {

  if (map && draw) {
    drawVectorLayer.getSource().clear();
    map.removeLayer(drawVectorLayer);
    map.removeInteraction(draw);
  }
};

export { draw, createDraw, clearDraw };
