import * as THREE from "three";

export function calculateBounds(bb) {
  let b = {}
  b.x = Math.abs(bb.max.x - bb.min.x)
  b.z = Math.abs(bb.max.z - bb.min.z)
  b.xMid = (bb.max.x + bb.min.x) / 2
  b.zMid = (bb.max.z + bb.min.z) / 2
  b.max = b.x >= b.z ? b.x : b.z
  b.min = b.x <= b.z ? b.x : b.z
  b.zoomMin = b.min * 0.75
  b.zoomMax = b.max * 4
  return b
}

export function computeWindingNormal(cords) {
  let cV = cords.map((v) => {
    return new THREE.Vector3(v[0],v[1],v[2])
  })
  const tri = new THREE.Triangle(cV[0],cV[1],cV[2])
  const triN = tri.getNormal(new THREE.Vector3())
  return triN
}

export function orderVertices(v,o) {
  let mV = []
  for (let i = 0; i < v.length; i += 3) {
    mV.push(v[i+o[0]])
    mV.push(v[i+o[1]])
    mV.push(v[i+o[2]])
  }
  return mV
}

export function getAvarageCenterPoint(items) {
  let acp = new THREE.Vector3(0,0,0)
  for(let i=0; i < items.length; i++) {
    acp.x += items[i].cP.x
    acp.y += items[i].cP.y
    acp.z += items[i].cP.z
  }
  acp.x = acp.x / items.length
  acp.y = acp.y / items.length
  acp.z = acp.z / items.length
  return acp
}

export function calculateLength(pA,pB,r) {
  let l = pA.distanceTo(pB)
  if (r >= 1) {
    l = round(l, r)
  }
  return l
}

export function calculateTotalLength(p) {
  let tl = 0
  if (p.length < 2) return tl
  for(let i=0; i<p.length-1; i++) {
    let s = calculateLength( p[i], p[i+1] )
    tl += s
  }
  return tl
}

export function calculateClosed(p) {
  if (p.length <= 3) {
    return false
  } else if (p[0].distanceTo(p[p.length-1]) <= 0.1) {
    return true
  }
}

export function round(n,d) {
  return parseFloat(n).toFixed(d)
}

export function weightedAverage(w,n) {
  let t = 0
  let wt = 0
  for(let i=0; i < n.length; i++) {
    t += w[i] * n[i]
    wt += w[i]
  }
  const wa = t / wt
  return wa
}

export function getMidPoint(pA,pB) {
  let d = pB.clone().sub(pA)
  let l = d.length()
  d = d.normalize().multiplyScalar(l/2)
  return pA.clone().add(d)
}

export function getAvarageMidPoint(p,c) {
  let amp = new THREE.Vector3(0,0,0)
  const length = c ? p.length-1 : p.length
  if (p.length >= 3) {
    for(let i=0; i < length; i++) {
      amp.x += p[i].x
      amp.y += p[i].y
      amp.z += p[i].z
    }
    amp.x = amp.x / length
    amp.y = amp.y / length
    amp.z = amp.z / length
  }
  return amp
}

export function getCenterPoint(g) {
  let cp = new THREE.Vector3()
  cp.x = (g.boundingBox.max.x + g.boundingBox.min.x) / 2
  cp.y = (g.boundingBox.max.y + g.boundingBox.min.y) / 2
  cp.z = (g.boundingBox.max.z + g.boundingBox.min.z) / 2
  return cp
}

export function getBearingCardinal(ba) {
  const cd = ["É", "ÉÉK", "ÉK", "KÉK", "K", "KDK", "DK", "DDK", "D", "DDNy", "DNy", "NyDNy", "Ny", "NyÉNy", "ÉNy", "ÉÉNy", "É"]
  const cg = 360 / (cd.length - 1)
  const bc = cd[Math.round(ba / cg)]
  return bc
}

export function transformLayerName(name) {
  switch(name) {
    case "Teto":
      return "Tető";
    case "Tetoablak":
      return "Tetőablak";
    case "Tetoelem":
      return "Tetőelem";
    case "Kemeny":
      return "Kémény";
    case "Gepeszetielem":
      return "Gépészeti elem";
    case "Tarejgerinc":
      return "Taréjgerinc";
    case "Elgerinc":
      return "Élgerinc";
    case "Oromszegely":
      return "Oromszegély";
    case "Vapa":
      return "Vápa";
    default:
      return name;
  }
}


export function createPath(points, radius, smoothness, closed = false) {

  radius = radius !== undefined ? radius : .1;
  smoothness = smoothness !== undefined ? Math.floor(smoothness) : 3;
  closed = closed !== undefined ? closed : false;

  // minimal segment
  let minVector = new THREE.Vector3();
  let minLength = minVector.subVectors(points[0], points[1]).length();
  for (let i = 1; i < points.length - 1; i++) {
    minLength = Math.min(minLength, minVector.subVectors(points[i], points[i + 1]).length());
  }
  if (closed) {
    minLength = Math.min(minLength, minVector.subVectors(points[points.length - 1], points[0]).length());
  }

  radius = radius > minLength * .5 ? minLength * .5 : radius; // radius can't be greater than a half of a minimal segment

  let startIndex = 1;
  let endIndex = points.length - 2;
  if (closed) {
    startIndex = 0;
    endIndex = points.length - 1;
  }

  let curvePath = new THREE.CurvePath();

  for (let i = startIndex; i <= endIndex; i++) {

    let iStart = i - 1 < 0 ? points.length - 1 : i - 1;
    let iMid = i;
    let iEnd = i + 1 > points.length - 1 ? 0 : i + 1;
    let pStart = points[iStart];
    let pMid = points[iMid];
    let pEnd = points[iEnd];

    // key points
    let keyStart = new THREE.Vector3().subVectors(pStart, pMid).normalize();
    let keyMid = pMid;
    let keyEnd = new THREE.Vector3().subVectors(pEnd, pMid).normalize();

    let halfAngle = keyStart.angleTo(keyEnd) * .5;

    let keyLength = radius / Math.tan(halfAngle);

    keyStart.multiplyScalar(keyLength).add(keyMid);
    keyEnd.multiplyScalar(keyLength).add(keyMid);

    curvePath.add(new THREE.QuadraticBezierCurve3(keyStart, keyMid, keyEnd));
  }

  let curvePoints = curvePath.getPoints(smoothness);

  let fullPoints = [];
  if (!closed) {
    fullPoints.push(points[0])
  }
  fullPoints = fullPoints.concat(curvePoints);
  if (!closed) {
    fullPoints.push(points[points.length - 1])
  } else {
    fullPoints.push(fullPoints[0].clone());
  }

  return new THREE.CatmullRomCurve3(fullPoints);
}