// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken
} from "../cookie";

// Store
import {
  setFreshRequest,
} from "../../data/store/Requests";


// Check error
const CheckError = (response, message) => {
  if (response.status === 200) {
    // Send success message to user
    message(["success", "Az igény sikeresen leadásra került!"]);
    return response.json();
  } else {
    // Send error message to user
    message(["error", "Hiba lépett fel az igény leadásakor!"]);
    throw Error(response.statusText);
  }
};

// Sending a request with selected position
const postNewRequest = async ( body, message ) => {

  await fetch(urls.postNewRequest, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
    body: JSON.stringify(body),
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      // Set the newly created request
      setFreshRequest(actualData.data.request_id, body);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default postNewRequest;
