// React imports
import { useState, useEffect, useMemo } from "react";
// Component imports
import { ButtonClose } from "../Molecules/Buttons";
import PanelNewRequest from "./PanelNewRequest";
import PanelPrevRequest from "./PanelPrevRequest";
import Icons from "../Icons/Icons";
import { useToastContext } from "../Toast/Toast";
// OL - Projection
import { transform } from "ol/proj";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";

// State
import { useSnapshot } from "valtio";
import StoreRequests, { setPanel } from "../../data/store/Requests";

// API
import getRequestByID from "../../api/request/getRequestByID";

// Define EPSG:23700 - EOV coordinate system
proj4.defs('EPSG:23700',
  '+proj=somerc +lat_0=47.1443937222222 +lon_0=19.0485717777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=57.01,-69.97,-9.29,0,0,0,0 +units=m +no_defs +type=crs');
register(proj4);


// Main Panel component
const Panel = () => {
  const {
    prevRequests,
    activePrevPin,
    newRequestPin,
    newRequestPinAddress,
    panelActive
  } = useSnapshot(StoreRequests);
  const [panelData, setPanelData] = useState(null);
  const addToast = useToastContext();


  useEffect(() => {
    if (panelActive) {

      // If new request
      if (newRequestPin) {
        setPanelData({
          type: "new",
          coordinates: {
            lng: newRequestPin.lng,
            lat: newRequestPin.lat
          },
          available: newRequestPin.available,
          surveyDate: newRequestPin.surveyDate,
          address: newRequestPinAddress
        });
      }

      // If previous request
      if (activePrevPin) {
        
        // Call API for detailed information
        getRequestByID(activePrevPin.request_id, addToast);

        // Basic information that we already have will be used for the frame
        const scopedRequest = prevRequests.find((e) => e.request_id === activePrevPin.request_id);
        
        // Set basic data
        if (scopedRequest) {
          setPanelData({
            type: "prev",
            request_id: scopedRequest.request_id,
            coordinates: {
              lng: scopedRequest.lng,
              lat: scopedRequest.lat
            },
            status: scopedRequest.status,
            address: scopedRequest.address
          });
        }
      }
    }
  }, [panelActive, newRequestPin, newRequestPinAddress, activePrevPin, prevRequests, addToast]);

  if (panelActive && panelData) {
    return (
      <div className="panel">
        
        <div className="panel__header">
          <div className="panel__header__content">
            <h4>
              {panelData.request_id
                ? `SVP-${panelData.request_id}`
                : "Új igény lokáció:"
              }
            </h4>
            <p>
              {panelData.address}
            </p>
            <div className="panel__header__tags">
              <PanelCoordinates coordinates={panelData.coordinates}/>
              { panelData.surveyDate && <PanelSurveyDate surveyDate={panelData.surveyDate}/> }
            </div>
          </div>
          <ButtonClose
            action={() => setPanel(false)}
          />
        </div>

        <div className="panel__content">
          { panelData.type === "new"
            ? <PanelNewRequest data={panelData} />
            : <PanelPrevRequest data={panelData}/>
          }
        </div>

      </div>
    );
  } else {
    return (
      <div className="panel"/>
    );
  }
};

const PanelCoordinates = ({coordinates}) => {
  // States
  const [activeCoordinateSystem, setActiveCoordinateSystem] = useState(0);
  const [convertedCoordinates, setConvertedCoordinates] = useState([]);
  const coordinateSystems = useMemo(() => [
    { epsg: "EPSG:4326", rounding: 6 },
    { epsg: "EPSG:23700", rounding: 2 },
    { epsg: "EPSG:3857", rounding: 2 },
  ],[]);

  // Handle active coordinate system change
  const changeActiveCoordinateSystem = () => {
    setActiveCoordinateSystem(
      activeCoordinateSystem < coordinateSystems.length - 1
      ? activeCoordinateSystem + 1
      : 0
    );
  };

  // Handle coordinate transformation when
  // coordinates or activeCoordinateSystem changes
  useEffect(() => {
    const converted = transform(
      [coordinates.lng, coordinates.lat],
      "EPSG:4326",
      coordinateSystems[activeCoordinateSystem].epsg
    );
    setConvertedCoordinates({
      lat: converted[1].toFixed(coordinateSystems[activeCoordinateSystem].rounding),
      lng: converted[0].toFixed(coordinateSystems[activeCoordinateSystem].rounding),
    });
  }, [coordinates, activeCoordinateSystem, coordinateSystems]);

  return (
    <button
      className="panel__header__coordinates"
      onClick={(e) => {
        e.preventDefault();
        changeActiveCoordinateSystem();
      }}
    >
      <span className="span--bold">
        {convertedCoordinates.lat + ", " + convertedCoordinates.lng}
      </span>
      <span>{coordinateSystems[activeCoordinateSystem].epsg}</span>
      <Icons.Convert />
    </button>
  );
};

const PanelSurveyDate = ({surveyDate}) => {
  return (
    <div className="panel__header__surveyDate">
      <span>Felmérés ideje: {surveyDate}</span>
    </div>
  );
};

export default Panel;