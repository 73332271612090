// Imports
import { useEffect, useState } from "react";
import { clearDraw, createDraw } from "./line.toolbar";
import { activeColorSwitch, toolIconsArr } from "./utils.toolbar";
import { preprocessToolsObject } from "../../utils/preProcessToolbar";
import { newPinLayer } from "../Map/Layers";
import { useSnapshot } from "valtio";
import StoreRequests, { setPanel } from "../../data/store/Requests";

export const Toolbar = ({ map, tools }) => {
  // Create reducer compatibility
  const preprocessedToolsObj = preprocessToolsObject(tools);
  const { panelActive } = useSnapshot(StoreRequests);

  // States
  const [activeTool, setActiveTool] = useState("");
  const [toolsDisplayed, setToolsDisplayed] = useState([]);
  const [firstRender, setFirstRender] = useState(true);

  // Click handler
  const onClickEvent = (key) => {
    // Set active button
    setActiveTool(key);

    // Additional tasks for "cursor" key
    if (key === "cursor") {
      map.addLayer(newPinLayer);
      clearDraw(map);
    }
    // Additional tasks for "ruler" key
    if (key === "ruler") {
      map.removeLayer(newPinLayer);
      if (panelActive) setPanel(false);
      clearDraw(map);
      createDraw(map, "LineString", 0);
    }
  };

  // ESC key event
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        if (map) {
          // Remove interaction
          clearDraw(map);
          createDraw(map, "LineString", 0);
        }
      }
    };

    // Subscribe to the ESC keydown event
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: unsubscribe from the ESC keydown event
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [map, setActiveTool]);

  // Init active tool
  useEffect(() => {
    if (
      firstRender &&
      (preprocessedToolsObj !== null || preprocessedToolsObj !== undefined)
    ) {
      setToolsDisplayed(preprocessedToolsObj);
      const initActiveTool = Object.keys(preprocessedToolsObj);
      setActiveTool(initActiveTool[0]);

      setFirstRender(false);
    }
  }, [preprocessedToolsObj, firstRender]);


  return (
    <div className="toolbar">
      {Object.keys(toolsDisplayed).map((key, index) => {
        const matchedIcon = toolIconsArr.find((item) => item.key === key);
        const Icon = matchedIcon ? matchedIcon.component : null;
        const originalToolIndex = toolIconsArr.findIndex(
          (tool) => tool.key === key
        );
        return (
          <button
            id={`tourSteps${originalToolIndex}`}
            key={index}
            className={`${activeTool === key ? "active" : key}`}
            onClick={() => onClickEvent(key)}
          >
            {Icon && <Icon color={activeColorSwitch(key, activeTool)} />}
          </button>
        );
      })}
    </div>
  );
};
