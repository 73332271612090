// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken
} from "../cookie";
// Store
import {
  setActiveRequestData
} from "../../data/store/Requests";
// Linked API
import getPCDFile from "./getPCDFile";


// Check error
const CheckError = (response, message) => {
  if (response.status === 200) {
    return response.json();
  } else {
    // Send error message to user
    message(["error", "Probléma lépett fel az adatok lekérdezésekor."]);
    throw Error(response.statusText);
  }
};

// Login
const getRequestByID = async (request_id, message) => {

  await fetch(urls.getRequestByID + request_id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      // Set returned requests data
      setActiveRequestData(actualData);
      // Try LiDAR PCD file
      if (actualData.model_json !== null) getPCDFile(request_id, message);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getRequestByID;
