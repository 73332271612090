import { proxy } from "valtio";

// STORE
const StoreDomain = proxy({
  domain_id: "",
  domain_name: "",
  company_name: "",
  company_postal: 0,
  company_city: "",
  company_address: "",
  company_vat: "",
  on_trial: true,
  is_active: false,
  cross_sharing: false,
  users: [],
});


// Set domain data from api data
export const SetDomain = (data) => {

  // Keys that need to be stored
  const domainDataToStore = [
    "domain_name",
    "company_name",
    "company_postal",
    "company_city",
    "company_address",
    "company_vat",
    "on_trial",
    "is_active",
    "cross_sharing",
    "users"
  ];

  // Store the values
  domainDataToStore.forEach((key) => {
    StoreDomain[key] = data[key];
  });
};

export default StoreDomain;