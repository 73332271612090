export const findLayerByName = (map, nameToFind) => {
  let targetLayer = null;

  map.getLayers().forEach((layer) => {
    if (layer.get("name") === nameToFind) {
      targetLayer = layer;
    }
  });

  return targetLayer;
};
