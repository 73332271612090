// INIT
//
// Initial call to get all data that needs to be
// represented uppon login or page refresh

// API calls
import getDomain from "./domain/getDomain";
import getRequestsByDomain from "./request/getRequestsByDomain";

// Init call
export const getInit = (domain_id) => {
  // Get the domain details after we got the domain_id
  getDomain(domain_id);
  // Get the domain details after we got the domain_id
  getRequestsByDomain(domain_id);
};

export default getInit;