// State and Valtio imports
import StoreModel from "../StoreModel";
import { useSnapshot } from "valtio";

// Component imports
import Toolbar from "./Toolbar";
import Viewbar from "./Viewbar";
import ViewActions from "./ViewActions";
import Help from "./Help";
import Fullscreen from "./Fullscreen";


// HUD
const HUD = ({model}) => {
  const { fullScreen } = useSnapshot(StoreModel.view);

  return (
    <>
      {fullScreen &&
        <>
          <Toolbar model={model}/>
          <Viewbar/>
          <ViewActions/>
          <Help.HelpButton/>
        </>
      }
      <Fullscreen/>
    </>
  )
};

export default HUD;