import { proxy } from "valtio";

// STORE
const StoreGlobal = proxy({
  userLoggedIn: false,
  user: {
    // User related data holder
    first_name: "",
    last_name: "",
    email: "",
    domain_id: "",
    id: "",
    is_admin: false,
  },
  searchInputOpen: false,
  searchBounds: [],
  surveyDate: null,
  searchPin: null,
  widgets: {
    toolbar: {
      cursor: true,
      ruler: true,
    },
  },
});

// --- USER ---
// Set user logged in
export const setUserLoggedIn = (state) => {
  StoreGlobal.userLoggedIn = state;
};

// Set user data from api data
export const setUser = (data) => {
  // Keys that need to be stored
  const needToStore = [
    "first_name",
    "last_name",
    "email",
    "domain_id",
    "id",
    "is_admin",
  ];

  // Store the values
  needToStore.forEach((key) => {
    StoreGlobal.user[key] = data[key];
  });
};

// --- SEARCH ---
// Set search input state
export const setSearchInput = (value) => {
  StoreGlobal.searchInputOpen = value;
};

// Set search bounds - the extent from a sucessful search
export const setSearchBounds = (SWLng, SWLat, NELng, NELat, setPin) => {
  StoreGlobal.searchBounds = [SWLng, SWLat, NELng, NELat];
  // Store search pin (if a pin can be placed)
  StoreGlobal.searchPin = setPin
    ? [(SWLng + NELng) / 2, (SWLat + NELat) / 2]
    : null;
};
// Set survey date
export const setSurveyDate = (value) => {
  StoreGlobal.surveyDate = value
    ? value.slice(0, 4) + "." + value.slice(4, 6) + "." + value.slice(6) + "."
    : null;
};

export default StoreGlobal;
