// imports
import { useSnapshot } from "valtio";
import { Toolbar } from "../Toolbar/Toolbar";
import StoreGlobal from "../../data/store/Global";

export const Maptools = ({ map }) => {
  const { widgets } = useSnapshot(StoreGlobal);
  return (
    <div className="maptools">
      <div className="maptools-left" />
      <div className="maptools-fill">
        <Toolbar map={map} tools={widgets.toolbar}/>
      </div>
    </div>
  );
};
