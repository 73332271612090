// Store
import {
  setNewRequestPinAddress
} from "../../data/store/Requests";

// Check error
const CheckError = (response) => {
  if (response.status === 200) {
    return response.json();
  } else {
    throw Error(response.statusText);
  }
};

// getProfile
const getGeocode = async (lat,lng) => {

  await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=hu&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}` , {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => {
      // Set resolved address
      setNewRequestPinAddress(actualData.results[0].formatted_address);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getGeocode;
