// React imports
import { useRef, useEffect, Suspense } from "react";

// Three
import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera, OrbitControls, GizmoHelper, GizmoViewcube, AdaptiveEvents, Preload } from "@react-three/drei";

import { useDrag } from "@use-gesture/react";

// StoreModel and Valtio imports
import { useSnapshot } from "valtio";
import StoreModel, {
  handleKeyDown,
  handleKeyUp,
  setMedia,
  ResetView,
} from "./StoreModel";
import StoreRequests from "../../data/store/Requests";

// Component imports
import Model from "./Model/Model";
import Pointer from "./Pointer";
import CursorTool from "./CursorTool";
import RulerTool from "./RulerTool";
import HUD from "./HUD/HUD";
import Help from "./HUD/Help";
import Ortomap from "./Ortomap";


// MODEL VIEWER
const ModelViewer = () => {
  const { request_id } = useSnapshot(StoreRequests.activeRequestData)
  const { bounds, ready } = useSnapshot(StoreModel.model);
  const { autoRotate, currentView, features, fullScreen, helpOpen, media } = useSnapshot(StoreModel.view);

  const cameraRef = useRef();
  const controlsRef = useRef();
  const modelRef = useRef();

  // Reset viewó
  useEffect(() => {
    if (request_id) ResetView();
  }, [request_id]);

  // Set Media type - only while initialized
  useEffect(() => {
    setMedia(window.innerWidth);
  }, []);

  // Scene Drag hook
  const dragBind = useDrag(({ active }) => {
    StoreModel.pointer.dragging = active;
  },{filterTaps: true, keys: false, modifierKey: null});

  return (
    <div className={`panel__content-box__viewer__model ${fullScreen ? "fullscreen" : ""}`}>
      
      <Ortomap />
      
      <div
        className={`model-viewer ${helpOpen ? "shrink" : ""}`}
        onKeyDown={(e) => {
          e.preventDefault();
          handleKeyDown(e)
        }}
        onKeyUp={(e) => {
          e.preventDefault();
          handleKeyUp(e)
        }}
        tabIndex="0"
        {...dragBind()}
      >
        <Canvas shadows>

          <fog attach="fog" args={["#FFF", bounds.zoomMax, bounds.zoomMax * 2]} />
          <color attach="background" args={["#FFF"]} />

          <Suspense fallback={null}>

            <hemisphereLight intensity={0.5} />
            <spotLight angle={0.5} position={[40, 100, 20]} castShadow={currentView === 0} shadow-bias={-0.00005} intensity={currentView === 0 ? 0.5 : 0} />
            <directionalLight color={currentView === 0 ? "#FFEAB7" : "#FFF"} position={[10, 15, -50]} intensity={1.5} />
            <directionalLight color={currentView === 0 ? "#FFC6A2" : "#FFF"} position={[10, 15, 50]} intensity={1} />
            
            <Model forwardedRef={modelRef}/>
            <mesh visible={currentView === 0 && features[0].active} scale={200} rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.02, 0]} receiveShadow >
              <planeGeometry />
              <shadowMaterial transparent opacity={0.05} />
            </mesh>
            <gridHelper receiveShadow args={[1000, 500, "#EEE", "#EEE"]} position={[0, -0.05, 0]} />

            <PerspectiveCamera
              ref={cameraRef}
              makeDefault
              position={[20, 10, 20]}
              far={500}
              near={0.1}
              fov={30}
            />
            <OrbitControls
              ref={controlsRef}
              makeDefault
              maxPolarAngle={Math.PI / 2}
              minDistance={bounds.zoomMin}
              maxDistance={bounds.zoomMax}
              autoRotate={autoRotate}
              autoRotateSpeed={-1.5}
            />

            {ready &&
            fullScreen &&
            media === "desktop" &&
              <GizmoHelper
                alignment="bottom-right"
                margin={[68, 68]}
              >
                <GizmoViewcube
                  faces={["Kelet","Nyugat","Tető","Alap","Dél","Észak"]}
                  color={"#FFF"}
                  hoverColor={"#00FF7F"}
                  strokeColor={"#0A0A29"}
                  textColor={"#0A0A29"}
                />
              </GizmoHelper>
            }

            {media === "desktop" &&
              <>
                <Pointer model={modelRef} camera={cameraRef} control={controlsRef} />
                <CursorTool/>
                <RulerTool model={modelRef} />
              </>
            }

            <AdaptiveEvents />
            <Preload all />

          </Suspense>
        </Canvas>
        <HUD model={modelRef}/>
      </div>
      <Help.HelpPanel/>
    </div>
  )
};

export default ModelViewer;