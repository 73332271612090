import { useSnapshot } from "valtio";
import state, { setDefaultService, setMobileMenuOpen } from "../../state";
import Icons from "../Icons/Icons";

const MobileMenu = () => {
  const snap = useSnapshot(state);
  return (
    <div
      className={`header__mobile-menu__sidebar ${
        snap.mobileMenuOpen ? "active" : ""
      }`}
    >
      <div>
        <div className="header__mobile-menu__sidebar-close">
          <button onClick={() => setMobileMenuOpen(false)}>
            <Icons.HamburgerClose />
          </button>
        </div>
        <div className="header__mobile-menu__sidebar-services">
          <ul>
            {snap.services.map((element) => {
              return (
                <li key={element.id}>
                  <button
                    onClick={() => {
                      setDefaultService(element);
                    }}
                    className={element.default ? "active" : ""}
                  >
                    {element.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="header__mobile-menu__sidebar-links">
        <ul>
          {snap.mobileLinks.map((element, index) => {
            return (
              <li key={index}>
                <a href={element.to}>
                  {element.name}
                </a>
              </li>
            );
          })}
          <hr/>
          <li>
                <a href="/">
                  Kijelentkezés
                  <Icons.ArrowRight />
                </a>
              </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
