import OLMap from "../Map/OLMap";

const ContentLeft = () => {
  return (
    <div className="content__left">
      <OLMap />
    </div>
  );
};

export default ContentLeft;