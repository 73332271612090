import { formatLength } from "./utils.toolbar";
import { LineString, MultiPoint, Point } from "ol/geom";
import { Modify } from "ol/interaction";
import VectorSource from "ol/source/Vector";
import {
  Circle as CircleStyle,
  Fill,
  RegularShape,
  Stroke,
  Style,
  Text,
} from "ol/style.js";

const style = new Style({
  fill: new Fill({
    color: "rgba(0, 255, 127, 0.3)",
  }),
  stroke: new Stroke({
    color: "#00FF7F",
    width: 2,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)",
    }),
  }),
});

const circleStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: "#FFFFFF",
    }),
    stroke: new Stroke({
      color: "#00FF7F",
      width: 3,
    }),
  }),
  geometry: (feature) => {
    const coordinates = feature.getGeometry().getCoordinates()[0];
    return new MultiPoint(coordinates);
  },
});


const lineStringDashStyle = new Style({
  geometry: (feature) => {
    const coordinates = feature.getGeometry().getCoordinates();
    return new LineString(coordinates.slice(-2));
  },
  stroke: new Stroke({
    color: "white",
    lineDash: [10, 10],
    width: 2,
  }),
});
const lineStringMultipontStyleOutter = new Style({
  geometry: (feature) => {
    const coordinates = feature.getGeometry().getCoordinates();
    return new MultiPoint(coordinates);
  },
  image: new CircleStyle({
    radius: 10,
    fill: new Fill({
      color: "rgba(10, 10, 41, 0.45)",
    }),
  }),
});
const lineStringMultipontStyle = new Style({
  geometry: (feature) => {
    const coordinates = feature.getGeometry().getCoordinates();
    return new MultiPoint(coordinates);
  },
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: "#0A0A29",
    }),
    stroke: new Stroke({
      color: "#FFFFFF",
      width: 3,
    }),
  }),
});


const lineStringAfterStyle = new Style({
  geometry: (feature) => {
    const coordinates = feature.getGeometry().getCoordinates();
    return new LineString(coordinates.slice(0, -1));
  },
  stroke: new Stroke({
    color: "#FFFFFF",
    width: 2,
    lineCap: "square",
    lineJoin: "round",
  }),
});

const labelStyle = new Style({
  text: new Text({
    font: "600 14px 'IBM Plex Sans', sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    padding: [3, 3, 3, 3],
    textBaseline: "bottom",
    offsetY: -15,
  }),
  image: new RegularShape({
    radius: 8,
    points: 3,
    angle: Math.PI,
    displacement: [0, 10],
    fill: new Fill({
      color: "rgba(0, 0, 0, 0.7)",
    }),
  }),
});

const tipStyle = new Style({
  text: new Text({
    font: "600 14px 'IBM Plex Sans', sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
    padding: [2, 2, 2, 2],
    textAlign: "left",
    offsetX: 15,
  }),
});

const modifyStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
  }),
  text: new Text({
    text: "Húzza arrébb a módosításhoz",
    font: "600 14px 'IBM Plex Sans', sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    padding: [2, 2, 2, 2],
    textAlign: "left",
    offsetX: 15,
  }),
});

const segmentStyle = new Style({
  text: new Text({
    font: "600 14px 'IBM Plex Sans', sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
    padding: [2, 2, 2, 2],
    textBaseline: "bottom",
    offsetY: -12,
  }),
  image: new RegularShape({
    radius: 6,
    points: 3,
    angle: Math.PI,
    displacement: [0, 8],
    fill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
  }),
});

const source = new VectorSource();
const modify = new Modify({ source: source, style: modifyStyle });
const segmentStyles = [segmentStyle];

let tipPoint;

const styleFunction = (feature, segments, drawType, tip) => {
  const styles = [];
  const geometry = feature.getGeometry();
  const type = geometry.getType();
  let point, label, line, coordinates;
  if (!drawType || drawType === type) {
    // Polygon
    if (type === "Polygon") {
      styles.push(style);
      point = geometry.getInteriorPoint();
      line = new LineString(geometry.getCoordinates()[0]);
    }
    // LineString
    else if (type === "LineString") {
      point = new Point(geometry.getLastCoordinate());
      label = formatLength(geometry);
      line = geometry;
      coordinates = feature.getGeometry().getCoordinates();

      // Add styles
      styles.push(lineStringDashStyle);
      styles.push(lineStringMultipontStyleOutter);
      styles.push(lineStringMultipontStyle);

      // Add after styles
      if (coordinates.length > 2) styles.push(lineStringAfterStyle);
    }
  }

  if (segments && line) {
    let count = 0;
    line.forEachSegment((a, b) => {
      const segment = new LineString([a, b]);
      const label = formatLength(segment);
      if (segmentStyles.length - 1 < count) {
        segmentStyles.push(segmentStyle.clone());
      }
      const segmentPoint = new Point(segment.getCoordinateAt(0.5));
      styles.push(lineStringMultipontStyleOutter);
      styles.push(circleStyle);
      segmentStyles[count].setGeometry(segmentPoint);
      segmentStyles[count].getText().setText(label);
      styles.push(segmentStyles[count]);
      count++;
    });
  }
  if (label) {
    labelStyle.setGeometry(point);
    labelStyle.getText().setText(label);
    styles.push(labelStyle);
  }
  if (
    tip &&
    type === "Point" &&
    !modify.getOverlay().getSource().getFeatures().length
  ) {
    tipPoint = geometry;
    tipStyle.getText().setText(tip);
    styles.push(tipStyle);
  }

  return styles;
};


// Create pattern for removeHighlightStyle
const canvas = document.createElement('canvas');
canvas.width = 10;
canvas.height = 10;
const ctx = canvas.getContext('2d');
ctx.strokeStyle = "#FF0055";
ctx.lineWidth = 3;
ctx.beginPath();
ctx.moveTo(0, 10);
ctx.lineTo(10, 0);
ctx.stroke();


export {
  tipPoint,
  source,
  modify,
  segmentStyle,
  modifyStyle,
  tipStyle,
  labelStyle,
  style,
  styleFunction,
};
