import Icons from "../Icons/Icons";
import Row from "../Layout/Row";
import { useSnapshot } from "valtio";
import state, { setMobileMenuOpen, setDropDownOpen } from "../../state";

import StoreGlobal from "../../data/store/Global";

import DropDown from "./DropDown";
import SearchBar from "./SearchBar";

const Actions = () => {
  const { searchInputOpen } = useSnapshot(StoreGlobal);
  const snap = useSnapshot(state);


  return (
    <div className="header__actions">
      <Row>
        <div
          className={`header__actions-search ${
            searchInputOpen ? "active" : ""
          }`}
        >
          <SearchBar />
        </div>
        <button
          className="header__actions-dropdown"
          onClick={() => {
            setDropDownOpen(snap.dropdownOpen);
          }}
        >
          <Icons.User />
          <Icons.ArrowDropDown />
        </button>
        {snap.dropdownOpen ? <DropDown /> : ""}
        <button
          className="header__actions-mobile"
          onClick={() => setMobileMenuOpen(true)}
        >
          <Icons.Hamburger />
        </button>
      </Row>
    </div>
  );
};

export default Actions;
