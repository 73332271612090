// OL Vector
import VectorLayer from "ol/layer/Vector";
import VectorSource from 'ol/source/Vector';
import { Cluster } from "ol/source";
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text,
} from "ol/style";
import { LineString } from "ol/geom";

// OL - Icon and marker
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import PinStyle from "./Pin";


// OL - Dynamic Clusters Variables
const circleDistanceMultiplier = 1;
const circleFootSeparation = 28;
const circleStartAngle = Math.PI / 2;

// Styles
const convexHullStroke = new Stroke({
  color: "#0A0A29",
  width: 2,
});
const outerCircleFill = new Fill({
  color: "#00FF7F",
});
const innerCircleFill = new Fill({
  color: "#0A0A29",
});
const textFill = new Fill({
  color: "#fff",
});
const innerCircle = new CircleStyle({
  radius: 15,
  fill: innerCircleFill,
});
const outerCircle = new CircleStyle({
  radius: 20,
  fill: outerCircleFill,
});



function clusterMemberStyle(clusterMember) {
  let style = PinStyle(
    clusterMember.status,
    clusterMember.selected
  );
  style.setGeometry(clusterMember.getGeometry());
  return style;
}

function generatePointsCircle(count, clusterCenter, resolution) {
  const circumference = circleDistanceMultiplier * circleFootSeparation * (2 + count);
  let legLength = circumference / (Math.PI * 2); //radius from circumference
  const angleStep = (Math.PI * 2) / count;
  const res = [];
  let angle;

  legLength = Math.max(legLength, 35) * resolution; // Minimum distance to get outside the cluster icon.

  for (let i = 0; i < count; ++i) {
    // Clockwise, like spiral.
    angle = circleStartAngle + i * angleStep;
    res.push([
      clusterCenter[0] + legLength * Math.cos(angle),
      clusterCenter[1] + legLength * Math.sin(angle),
    ]);
  }

  return res;
}


// ClUSTER SOURCE
const clusterSource = new Cluster({
  distance: 35,
  source: new VectorSource()
});


// CLUSTERS - Layer displaying the clusters and individual features.
function clusterStyle(feature) {
  const size = feature.get("features").length;
  if (size > 1) {
    return [
      new Style({
        image: outerCircle,
      }),
      new Style({
        image: innerCircle,
        text: new Text({
          text: size.toString(),
          fill: textFill,
          scale: 1.5
        }),
      }),
    ];
  } else {
    const originalFeature = feature.get("features")[0];
    return clusterMemberStyle(originalFeature);
  }
}

export const clusters = new VectorLayer({
  source: clusterSource,
  style: clusterStyle,
});


// CLUSTER CIRCLES - Layer displaying the expanded view of overlapping cluster members.
let clickFeature, clickResolution;
export function clusterCircleStyle(cluster, resolution) {
  if (cluster !== clickFeature || resolution !== clickResolution) {
    return;
  }
  const clusterMembers = cluster.get("features");
  const centerCoordinates = cluster.getGeometry().getCoordinates();
  return generatePointsCircle(
    clusterMembers.length,
    cluster.getGeometry().getCoordinates(),
    resolution
  ).reduce((styles, coordinates, i) => {
    const point = new Point(coordinates);
    const line = new LineString([centerCoordinates, coordinates]);
    styles.unshift(
      new Style({
        geometry: line,
        stroke: convexHullStroke,
      })
    );
    styles.push(
      clusterMemberStyle(
        new Feature({
          ...clusterMembers[i].getProperties(),
          geometry: point,
        })
      )
    );
    return styles;
  }, []);
}

export const clusterCircles = new VectorLayer({
  source: clusterSource,
  style: clusterCircleStyle,
});