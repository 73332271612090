import { DimensionInputs } from "./Input";

export const RadioButton = ({
  index,
  item,
  name,
  onChange,
  value,
  buildingType,
  trial,
  inputs = false,
  formDataSolarCustomDimension,
  handleCustomInputDimension,
}) => {
  const formatNumber = (number) => {
    return number !== undefined ? number.toLocaleString("hu-HU") : null;
  };

  return (
    <div
      className={`checkbox-wrapper ${index === Number(value) ? "active" : ""} ${
        inputs && index === Number(value) ? "align-top" : ""
      }`}
    >
      <label>
        <input
          type="radio"
          name={name}
          value={index}
          checked={index === Number(value)}
          onChange={onChange}
        />
        <div className="checkbox-content">
          <div className="checkbox-content__icon" />
          <div className="checkbox-content__label">
            {item.name ? (
              <>
                <h5>{item.name}</h5>
                {buildingType === "Lakóingatlan" ? (
                  <h6>
                    {trial
                      ? "Ingyenes"
                      : `${formatNumber(item.priceResidential)} Ft`}
                  </h6>
                ) : (
                  <h6>{`${formatNumber(item.priceIndustrial)} Ft/m²`}</h6>
                )}
              </>
            ) : (
              <div>
                <h5>{item}</h5>
                {inputs && index === Number(value) && (
                  <DimensionInputs
                    formDataSolarCustomDimension={formDataSolarCustomDimension}
                    handleCustomInputDimension={handleCustomInputDimension}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </label>
    </div>
  );
};
