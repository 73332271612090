// State
import { useSnapshot } from "valtio";
import StoreGlobal from "../../data/store/Global";

// Components
import Row from "./Row";


const ContentTop = ({ children }) => {
  const { searchInputOpen } = useSnapshot(StoreGlobal);

  return (
    <div className={`content__left-top ${searchInputOpen ? "searching" : ""}`} >
      <Row>{children}</Row>
    </div>
  );
};

export default ContentTop;
