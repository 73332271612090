// Cookies Import
import Cookies from "universal-cookie";

// Initialize cookies
const cookies = new Cookies();

// Domain option
const domain = process.env.NODE_ENV === "development" ? "localhost" : ".solarvp.hu"


// Token
export const cookieGetToken = () => {
  return cookies.get("userToken", { domain: domain });
};
export const cookieRemoveToken = () => {
  cookies.remove("userToken", { domain: domain });
};

// TempPassword
export const cookieGetTempPassword = () => {
  return cookies.get("tempPassword", { domain: domain });
};