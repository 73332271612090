import { useCallback, useContext, useState, createContext } from "react";
import Icons from "../Icons/Icons";
import Row from "../Layout/Row";

const ToastContext = createContext();

export default ToastContext;

export const ToastContextProvider = ({ children  }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (toast) => {
      if(toasts.length === 0) {
        setToasts((toasts) => [...toasts, toast]);
        setTimeout(() => setToasts((toasts) => toasts.slice(1)), 3000);
      }
    },
    [setToasts, toasts]
  );

  const removeToast = () => {
    setToasts((toasts) => toasts.slice(1));
  };
  return (
    <ToastContext.Provider value={addToast}>
      {children}
      {toasts.map((message, index) => {
        return (
          <div className={`toast show ${message[0]}`}  key={index}>
            <div className="toast-content">
              <Row>
                <p>{message[1]}</p>
                <button onClick={() => removeToast()}>
                  <Icons.HamburgerClose />
                </button>
              </Row>
            </div>
          </div>
        );
      })}
    </ToastContext.Provider>
  );
};

export function useToastContext() {
  return useContext(ToastContext);
}
