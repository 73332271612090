import Icons from "../Icons/Icons";
import { useSnapshot } from "valtio";
import state from "../../state";

// Cookie import
import { cookieRemoveToken } from "../../api/cookie";


const DropDown = () => {
  const snap = useSnapshot(state);

  const handleLogout = (e) => {
    e.preventDefault();
    // Remove usertoken from cookie
    cookieRemoveToken();
    // Reload window
    window.location.reload();
  };

  return (
    <div className="header__actions__dropdown-menu">
      <ul>
        {snap.mobileLinks.map((element, index) => {
          return (
            <li key={index}>
              <a href={element.to}>{element.name}</a>
            </li>
          );
        })}
        <hr />
        <li>
          <a href="/" onClick={(e) => handleLogout(e)}>
            Kijelentkezés
            <Icons.ArrowRight />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default DropDown;
