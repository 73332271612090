// Valtio import
import { proxy } from "valtio";
// OpenLayers
import { transform } from "ol/proj";
// Store imports
import StoreGlobal from "./Global";
import StoreDomain from "./Domain";

// Initial data structures
const initActiveRequestData = {
  request_id: 0,
  building_type: "",
  building_features: 0,
  status: "",
  comment: "",
  created_at: "",
  updated_at: "",
  user: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
  },
  domain: {
    domain_id: "",
    domain_name: "",
  },
  model_json: null,
  pcd_file: null,
};

// STORE
const StoreRequests = proxy({
  // Previous requests
  prevRequests: [],
  activePrevPin: null,

  // Detailed data for active request
  activeRequestData: initActiveRequestData,

  // New request
  newRequestPin: null,
  newRequestPinAddress: "",

  // Actual panel state
  panelActive: false,

  // Change tracking
  requestChangeTrack: 0,

  // Request canvas
  canvasIMG: null,
});

// Set previous requests from API
export const setRequests = (requests) => {
  let prevRequestsArray = [];

  // Mapping data and add to array
  requests.map((request) => {
    let requestObj = {
      request_id: request.request_id,
      status: request.status,
      lat: request.lat,
      lng: request.lon,
      address: request.resolved_addr,
      user_id: request.user.id,
    };
    return prevRequestsArray.push(requestObj);
  });

  // Filter requests if user is not admin and cross sharing is disabled
  if (!StoreGlobal.user.is_admin && !StoreDomain.cross_sharing) {
    prevRequestsArray = prevRequestsArray.filter(
      (request) => request.user_id === StoreGlobal.user.id
    );
  }

  StoreRequests.prevRequests = prevRequestsArray;
};

// Set selected pin based on initial URL Search Param
export const getInitialUrlId = (id) => {
  let findRequestID = StoreRequests.prevRequests.find((e) => e.id === id);

  if (findRequestID) {
    const coords = transform(
      [findRequestID.lng, findRequestID.lat],
      "EPSG:4326",
      "EPSG:3857"
    );
    setActivePrevPin({
      id: findRequestID.id,
      coordinates: coords,
    });
  }
};

// Set selected pin when user click on a clusterPin
export const setActivePrevPin = (pin) => {
  StoreRequests.activePrevPin = pin;
  setPanel(true);
};

// Clear selected pin when user closes panel - fired with setPanel(false)
export const clearActivePrevPin = () => {
  StoreRequests.activePrevPin = null;
  clearActiveRequestData();
};

// Set detailed data for user scoped request
export const setActiveRequestData = (request) => {
  // Keys that need to be stored
  const userDataToStore = [
    "request_id",
    "building_type",
    "building_features",
    "status",
    "comment",
    "created_at",
    "updated_at",
    "user",
    "domain",
    "model_json",
  ];

  // Store the values
  userDataToStore.forEach((key) => {
    StoreRequests.activeRequestData[key] = request[key];
  });
};

// Set PCD file for the LiDAR view
export const setActiveRequestPCD = (blob) => {
  StoreRequests.activeRequestData.pcd_file = blob;
};

// Clear all data for user scoped request
export const clearActiveRequestData = () => {
  StoreRequests.activeRequestData = initActiveRequestData;
};

// Set new request pin when user click on the map
export const setNewRequestPin = (lng, lat, availability, surveyDate) => {
  StoreRequests.newRequestPin = {
    lat: lat,
    lng: lng,
    available: availability,
    surveyDate: surveyDate,
  };
  setPanel(true);
};

// Clear new request pin when user closes panel - fired with setPanel(false)
export const clearNewRequestPin = () => {
  StoreRequests.newRequestPin = null;
};

// Set resolved Address from Google API
export const setNewRequestPinAddress = (address) => {
  StoreRequests.newRequestPinAddress = address;
};

// Set marker state
export const setPanel = (value) => {
  StoreRequests.panelActive = value;
  if (!value) {
    clearNewRequestPin();
    clearActivePrevPin();
  }
};

// Set freshly requested pin
export const setFreshRequest = (request_id, body) => {
  // Build request object
  const requestObj = {
    request_id: request_id,
    status: "Pending",
    lat: body.lat,
    lng: body.lon,
    address: body.resolved_addr,
  };

  // Push request object to store array
  StoreRequests.prevRequests.push(requestObj);

  // Modify change track to initiate render
  setRequestsChangeTrack(request_id);

  // Close Panel
  setPanel(false);
};

// Set canvasImage
export const setCanvasImage = (dataURL) => {
  StoreRequests.canvasIMG = dataURL;
};

// Set marker state
export const setRequestsChangeTrack = (request_id) => {
  StoreRequests.requestChangeTrack = request_id;
};

export default StoreRequests;
