export const DimensionInputs = ({
  formDataSolarCustomDimension,
  handleCustomInputDimension,
}) => {
  return (
    <div className="dimension-inputs">
      <div>
        <label htmlFor="">Hosszúság (mm)</label>
        <input
          value={formDataSolarCustomDimension.dimensionHeight}
          name="dimensionHeight"
          type="number"
          onChange={handleCustomInputDimension}
        />
      </div>
      <div className="dimension-inputs__separator">
        <span>X</span>
      </div>
      <div>
        <label htmlFor="">Szélesség (mm)</label>
        <input
          value={formDataSolarCustomDimension.dimensionWidth}
          name="dimensionWidth"
          type="number"
          onChange={handleCustomInputDimension}
        />
      </div>
    </div>
  );
};
