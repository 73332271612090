// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken
} from "../cookie";
// Store
import { setActiveRequestPCD } from "../../data/store/Requests";



// Check error
const CheckError = (response) => {
  if (response.status === 200) {
    return response.blob();
  } else {
    throw Error(response.statusText);
  }
};

// Get PCD File
const getPCDFile = async (request_id, message) => {

  await fetch(urls.getFile + request_id + "/pcd", {
    method: "GET",
    headers: {
      accept: "*/*",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    // Return response data as blob and checking errors
    .then((response) => CheckError(response, message))
    .then((bytes) => {
      // Create Object URL
      const pcdObj = URL.createObjectURL(bytes);
      // Set PCD
      setActiveRequestPCD(pcdObj);
    }).catch((error) => {
      console.log(error);
    })
};

export default getPCDFile;