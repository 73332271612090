// React imports
import { useState, useEffect } from "react";

// Three imports
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { BakeShadows, Bounds, useBounds } from "@react-three/drei";

// Use Gesture imports
import { useHover } from "@use-gesture/react";

// State and Valtio imports
import StoreModel, {
  handlePointerClick,
  setReady
} from "../StoreModel";
import { useSnapshot } from "valtio";
import StoreRequests from "../../../data/store/Requests";

// Utils import
import { calculateBounds } from "../utils";

// Component imports
import { parseSides, parseEdges, parseSolars } from "./ParseJSON";
import Side from "./renderSide";
import Edge from "./renderEdge";
import Solar from "./renderSolar";
import Lidar from "./Lidar";
import BasePlane from "./BasePlane";


const Model = ({ forwardedRef }) => {
  const { model_json, pcd_file } = useSnapshot(StoreRequests.activeRequestData);

  const [geometry, setGeometry] = useState({
    sides: [],
    edges: [],
    solars: [],
  });

  // Fetch JSON and build sides geometry
  useEffect(() => {
    if (model_json) {

      // Set gps loaction
      StoreModel.model.gps = model_json.gps;

      // Parse
      const sides = parseSides( model_json.features.sides, 0 );
      const edges = parseEdges( model_json.features.edges, sides.index );
      const solars = parseSolars( model_json.features.solars, edges.index );

      setGeometry({
        sides: sides.features,
        edges: edges.features,
        solars: solars.features
      });
    }
  }, [model_json]);

  useEffect(() => {
    if (geometry.sides.length > 0) {
      StoreModel.view.features[0].available = true;
      StoreModel.view.features[0].active = true;
    }
    if (geometry.edges.length > 0) {
      StoreModel.view.features[1].available = true;
      StoreModel.view.features[1].active = true;
    }
    if (geometry.solars.length > 0) {
      StoreModel.view.features[2].available = true;
      StoreModel.view.features[2].active = true;
    }
  }, [geometry]);


  if (geometry.sides.length > 0) {
    return (
      <>
        <Bounds fit observe damping={4} margin={1.2}>
          <Building forwardedRef={forwardedRef} geometry={geometry} />
        </Bounds>
        {pcd_file && <Lidar/>}
        <BasePlane/>
        <BakeShadows />
      </>
    )
  }
};

const Building = ({ forwardedRef, geometry }) => {
  const { loaded } = useSnapshot(StoreModel.model);
  const { currentView, homeingTrack, features, fullScreen } = useSnapshot(StoreModel.view);
  const { currentTool } = useSnapshot(StoreModel.tool);
  const { selectionEmptyTrack } = useSnapshot(StoreModel.cursor);

  const bounds = useBounds();
  const renderer = useThree();
  
  // Bounding Box calculation
  useEffect(() => {
    let bb = new THREE.Box3().setFromObject(forwardedRef.current);
    forwardedRef.current.geometry.boundingBox = bb;
    StoreModel.model.bounds = calculateBounds(bb);
    // Change StoreModel to ready
    setReady();
    // Refresh bounds
    bounds.refresh().fit();
    // Refresh shadows
    renderer.gl.shadowMap.needsUpdate = true;
  }, [loaded, bounds, renderer,forwardedRef]);

  useEffect(() => {
    bounds.refresh().fit();
  }, [homeingTrack, selectionEmptyTrack, fullScreen, bounds]);

  // Hover handling
  const hoverBind = useHover(({ active }) => {
    StoreModel.pointer.visible = active;
    document.body.style.cursor = active ? "none" : "default";
  });

  // SIDES
  const sides = geometry.sides.map((side, i) => {
    return <Side key={i} data={side} view={currentView} />;
  });
  // EDGES
  const edges = geometry.edges.map((edge, i) => {
    return <Edge key={i} data={edge} />;
  });
  // SOLARS
  const solars = geometry.solars.map((group, i) => {
    return <Solar key={i} data={group} view={currentView} />;
  });

  return (
    <mesh 
      ref={forwardedRef}
      receiveShadow
      onUpdate={() => StoreModel.model.loaded = true}
      onClick={(e) => {
        currentTool === 1 && 
          e.stopPropagation();
          handlePointerClick()
      }}
      {...hoverBind()}
    > 
      <group name="sides">
        {features[0].active && sides}
      </group>
      <group name="edges">
        {features[1].active && edges}
      </group>
      <group name="solars">
        {features[2].active && solars}
      </group>
    </mesh>
  )
};

export default Model;