// State and Valtio imports
import StoreModel, {
  setCurrentTool
} from "../StoreModel";
import { useSnapshot } from "valtio";

// Component imports
import Icons from "../../Icons/Icons";
import { exportOBJ } from "../Model/OBJExporter";
import StoreRequests from "../../../data/store/Requests";


// TOOLBAR
const Toolbar = ({model}) => {
  return (
    <ul className="toolbar">
      <CursorTool/>
      <RulerTool/>
      <ExportTool model={model}/>
    </ul>
  )
};

const CursorTool = () => {
  const { currentTool, tools } = useSnapshot(StoreModel.tool);
  const { shiftPressed } = useSnapshot(StoreModel.cursor);

  return(
    <li 
      className={currentTool === 0 ? "active" : ""}
      title={tools[0]}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentTool(0)
      }}
    >
      <Icons.Cursor
        multi={shiftPressed}
        color={currentTool === 0 ? null : "white"}
      />
    </li>
  )
};

const RulerTool = () => {
  const { currentTool, tools } = useSnapshot(StoreModel.tool);

  return(
    <li 
      className={currentTool === 1 ? "active" : ""}
      title={tools[1]}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentTool(1)
      }}
    >
      <Icons.Ruler
        color={currentTool === 1 ? null : "white"}
      />
    </li>
  )
};

const ExportTool = ({model}) => {
  const { tools } = useSnapshot(StoreModel.tool);
  const { request_id } = useSnapshot(StoreRequests.activeRequestData);

  // Downloaded file nameing
  const filename = `SVP-${request_id}_OBJ`;

  return(
    <li
      title={tools[2]}
      onClick={(e) => {
        e.stopPropagation();
        exportOBJ({model, filename})
      }}
    >
      <Icons.Download
        color="white"
      />
    </li>
  )
};

export default Toolbar;