import * as THREE from "three";


const Edge = ({ data }) => {

  if (data) {
    return (
      <>
        <mesh
          geometry={data}
          castShadow
          receiveShadow
        >
          <EdgeTexture layer={data.userData.layer} />
        </mesh>
      </>
    )
  } else return;
};

const EdgeTexture = ({ layer }) => {

  const fillColor = {
    Tarejgerinc:  "#FF6B00",
    Elgerinc:     "#FF005C",
    Oromszegely:  "#00A3FF",
    Eresz:        "#FA00FF",
    Vapa:         "#9E00FF",
  };

  return (
    <meshBasicMaterial
      side={THREE.DoubleSide}
      color={fillColor[layer]}
    />
  )
};

export default Edge;