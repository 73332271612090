// MODEL VIEWER EMPTY
const ModelViewerEmpty = () => {

  return (
    <div className="panel__content-box__viewer__model empty">
      <h5>Jelenleg nem áll rendelkezésre<br/>adat a 3D nézet megjelenítésére</h5>
    </div>
  )
};

export default ModelViewerEmpty;